import '../css/form_citas.css'

export default function MyForm(){
      
    return (
        <div className="row">
            <div className="col-lg-12 form-group">          
                <fieldset>                     
                    <div className="alert alert-success alert-dismissable">
                        <div className="row text-justify">
                            <div className="col-md-12">
                                Correspondencia - ARCSA                       
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>         
            <div className="container">
                <div className="row">
                    <div className="col-lg-1">
                    </div>
                    <div className="col-lg-4">
                        <div className="card card-margin">
                            <div className="card-header no-border">
                            </div>
                            <div className="card-body pt-0">
                                <a href="/correspondencia-general">
                                    <div className="widget-49">
                                        <div className="widget-49-title-wrapper text-center">
                                            <div className="widget-49-date-primary">
                                                <img className="card-img-top" src="../img/arcsa/sobre.png" alt="Card" style={{width:"100%"}}/>
                                            </div>
                                            <div className="widget-49-meeting-info">
                                                <span className="widget-49-pro-title">Consulta General</span>
                                            </div>
                                        </div>
                                        <ol className="widget-49-meeting-points">
                                            <span>En este link podrá ingresar una correspondencia para alguna consulta en general de los servicios que brinda el ARCSA</span>
                                        </ol> 
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <div className="col-lg-4">
                        <div className="card card-margin">
                            <div className="card-header no-border">
                            </div>
                            <div className="card-body pt-0">
                                <a href="/correspondencia-tramite">
                                    <div className="widget-49">
                                        <div className="widget-49-title-wrapper">
                                            <div className="widget-49-date-success">
                                                <img className="card-img-top" src="../img/arcsa/sobre_doc.png" alt="Card" style={{width:"100%"}}/>
                                            </div>
                                            <div className="widget-49-meeting-info">
                                                <span className="widget-49-pro-title">Consulta de trámites en curso </span>
                                            </div>                                          
                                        </div>
                                        <ol className="widget-49-meeting-points">
                                            <span>En este link podrá ingresar una correspondencia para alguna consulta de un trámite ingresado en el ARCSA</span>
                                        </ol> 
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
      
}