import React from 'react';
import '../css/chatbot.css'


import Chat from './chat'

function ChatBot(){
    return(        
        < Chat />          
    )
}

export default ChatBot;