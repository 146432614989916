import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import '../css/faq.css'

const url_consulta= "/servicios/faq_arcsa";
const urlGuardarLogs = "/servicios/guardar_faq_log";

function Faq(){
    
    useEffect(() => {
        
        let formData = new FormData();
    formData.append('arcsa', '');
         const requestOptions = {
            method: 'POST',
            body: formData
        };
        const fetchItems = async () => {        
            const data = await fetch (url_consulta,requestOptions);
            const items = await data.json();
            setItemsPadre(items.data[0]); 
            setItemsDetails(items.data[1]); 
        }
        fetchItems();
    },[]);

    const [itemsPadre, setItemsPadre] = useState([]);
    const [itemsDetails, setItemsDetails] = useState([]);
   

   
   

    const [state, setState] = React.useState({
        faq_head : ""
    })
    let formData = new FormData();
    const funcionLog = async (catgoria,op) => {
        formData.append('proceso', catgoria);
        formData.append('op', op);
        const requestOptions = {
            method: 'POST',
            body: formData
        };
        await fetch (urlGuardarLogs,requestOptions);
        return 1;
    }
    const handleClickCategorias = () => {
        const categorias = document.querySelectorAll('#categorias .categoria');
        const contenedorPreguntas = document.querySelectorAll('.contenedor-preguntas');        
        let categoriaActiva = null;
        categorias.forEach((categoria) => {
            categoria.addEventListener('click', (e) => {
                categorias.forEach((elemento) => {
                    elemento.classList.remove('activa');
                });

                e.currentTarget.classList.toggle('activa');
                categoriaActiva = categoria.dataset.categoria;
                setState({
                    faq_head : categoria.dataset.categoria
                });
                // Activamos el contenedor de preguntas que corresponde
                contenedorPreguntas.forEach((contenedor) => {
                    if(contenedor.dataset.categoria === categoriaActiva){
                        contenedor.classList.add('activo');
                    } else {
                        contenedor.classList.remove('activo');
                    }
                });
            });
        });   
        //guardamos la busqueda
        funcionLog(state.faq_head, 0);
        window.scrollTo(0, 0);
        // 
    };
  
    const handleClickPregRes = () => {  
        const preguntas = document.querySelectorAll('.preguntas .contenedor-pregunta');
        preguntas.forEach((pregunta) => {
            pregunta.addEventListener('click', (e) => {
                e.currentTarget.classList.toggle('activa');
                const respuesta = pregunta.querySelector('.respuesta');
                const alturaRealRespuesta = respuesta.scrollHeight;
                if(!respuesta.style.maxHeight){
                    // Si esta vacio el maxHeight entonces ponemos un valor.
                   // alert(e);
                    respuesta.style.maxHeight = alturaRealRespuesta + 'px';
                } else {
                    respuesta.style.maxHeight = null;
                }
                // [Opcional] Reiniciamos las demas preguntas
                preguntas.forEach((elemento) => {
                    // Solamente queremos ejecutar el codigo para las preguntas que no 
                    // sean la pregunta a la que le dimos click.
                    if(pregunta !== elemento){                        
                        elemento.classList.remove('activa');
                        elemento.querySelector('.respuesta').style.maxHeight = null;
                    }
                });                
            });            
        }); 
    };
    
    return(

        <main id="main"> 
            <section className="section">   
                <div className="col-md-12">
                    <div className="sidebar">
                        <div className="sidebar-item categories img-back" >
                            <div className="container">
                                     {/* <Tabs defaultActiveKey="second">
                                        {itemsPadre.map(val => ( 
                                            <Tab eventKey={val.tab} title={val.pregunta}>
                                                <div className="row">
                                                    <div className="col-lg-3">   
                                                        <div className="categorias" id="categorias">                                                                                                
                                                            <div  key={val.id} className={val.class_header} data-categoria={val.data_categoria}>
                                                                <img className="img-responsive"   src={val.img} alt="Abrir Respuesta" />
                                                                <p  >{val.pregunta}</p>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9">   
                                                        <div  className="preguntas">                                                                                             
                                                            <div onClick={handleClickPregRes}  key={val.id} className={val.class_header_pregunta} data-categoria={val.data_categoria}>
                                                                {itemsDetails.map(val2 => (
                                                                    val2.tipo === "p" &&                                                                    
                                                                    val.data_categoria === val2.data_categoria &&
                                                                    <div  key={val2.deta} className={val2.class_pregunta_padre}>
                                                                        <p   className="pregunta">{val2.label} <img  src="assets/img/suma.svg" alt="Abrir Respuesta" /></p>
                                                                        {itemsDetails.map(val3 => (
                                                                            val3.padre_id !== "0" && 
                                                                            val3.tipo === "r" &&
                                                                            val3.padre_id === val2.deta &&                                                                            
                                                                            val2.data_categoria === val3.data_categoria &&
                                                                            <p key={val3.label}  className='respuesta' style={{maxHeight: val3.style_res}} dangerouslySetInnerHTML={{ __html: val3.label }} />                                                                        
                                                                        ))}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                            ))
                                        }
                                       
                                      </Tabs>*/}
                                
                                <br/>
                                <div className="row">
                                    <div className="col-lg-3">   
                                        <div onClick={handleClickCategorias} className="categorias" id="categorias">
                                        {itemsPadre.map(val => (                                           
                                            <div  key={val.id} className={val.class_header} data-categoria={val.data_categoria}>
                                                <img className="img-responsive"   src={val.img} alt="Abrir Respuesta" />
                                                <p  >{val.pregunta}</p>
                                            </div>                                            
                                            ))
                                        } 
                                        </div>
                                    </div>
                                    <div className="col-lg-9">   
                                        <div  className="preguntas">
                                            {itemsPadre.map(val => ( 	                                                           									
                                                <div onClick={handleClickPregRes}  key={val.id} className={val.class_header_pregunta} data-categoria={val.data_categoria}>
                                                    {itemsDetails.map(val2 => (
                                                        val2.tipo === "p" &&                                                                    
                                                        val.data_categoria === val2.data_categoria &&
                                                        <div  key={val2.deta} className={val2.class_pregunta_padre}>
                                                            <p   className="pregunta">{val2.label} <img  src="http://172.16.0.22/siarc/assets/img/suma.svg" alt="Abrir Respuesta" /></p>
                                                            {itemsDetails.map(val3 => (
                                                                val3.padre_id !== "0" && 
                                                                val3.tipo === "r" &&
                                                                val3.padre_id === val2.deta &&                                                                            
                                                                val2.data_categoria === val3.data_categoria &&
                                                                <p key={val3.label}  className='respuesta' style={{maxHeight: val3.style_res}} dangerouslySetInnerHTML={{ __html: val3.label }} />                                                                        
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                            ))
                                            }
                                        </div>
                                    </div>
                                
                                </div>
                            </div>                    
                        </div>
                        <br/>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Faq;