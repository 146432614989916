import '../css/form_citas.css'

export default function MyForm(){
      
    return (
        <div className="row">
            <div className="col-lg-12 form-group">          
                <fieldset>                    
                    <div className="alert alert-success alert-dismissable">
                        <div className="row text-justify">
                            <div className="col-md-12">
                                Citas Virtuales - ARCSA                       
                            </div>
                            <div className="col-md-12">
                                Solicite su cita virtual si requiere atención especializada, orientación e información operativa, técnica o normativa respecto a los trámites y servicios ofrecidos por el ARCSA.
                                Las citas son llevadas a cabo de manera virtual a través de la aplicación ZOOM.                       
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>         
            <div className="container">
                <div className="row">
                    <div className="col-lg-1">
                    </div>
                    <div className="col-lg-4">
                        <div className="card card-margin">
                            <div className="card-header no-border">
                            </div>
                            <div className="card-body pt-0">
                                <a href="/citas-temas">
                                    <div className="widget-49">
                                        <div className="widget-49-title-wrapper text-center">
                                            <div className="widget-49-date-primary">
                                                <img className="card-img-top" src="../img/arcsa/citas_logo.png" alt="Card" style={{width:"100%"}}/>
                                            </div>
                                            <div className="widget-49-meeting-info">
                                                <span className="widget-49-pro-title">Solicitar nueva cita virtual</span>
                                                <span className="widget-49-meeting-time">09:00 a 16.30 Hrs</span>
                                            </div>
                                        </div>
                                        <ol className="widget-49-meeting-points">
                                            <li className="widget-49-meeting-item"><span>En este link puede ingresar y agendar su cita con ARCSA</span></li>
                                            <li className="widget-49-meeting-item"><span>Duración de 30 minutos</span></li>
                                            <li className="widget-49-meeting-item"><span>Las preguntas serán evaludas</span></li>
                                        </ol>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <div className="col-lg-4">
                        <div className="card card-margin">
                            <div className="card-header no-border">
                            </div>
                            <div className="card-body pt-0">
                                <a href="/citas-estado">
                                    <div className="widget-49">
                                        <div className="widget-49-title-wrapper">
                                            <div className="widget-49-date-success">
                                                <img className="card-img-top" src="../img/arcsa/citas_logo_edit.png" alt="Card" style={{width:"100%"}}/>
                                            </div>
                                            <div className="widget-49-meeting-info">
                                                <span className="widget-49-pro-title">Consulte el estado de agendamiento</span>
                                                <span className="widget-49-meeting-time">Disponible 24/7 </span>
                                            </div>
                                        </div>
                                        <ol className="widget-49-meeting-points">
                                            <li className="widget-49-meeting-item"><span>En este link se puede visualizar el estado actual de su cita</span></li>
                                            <li className="widget-49-meeting-item"><span>Las citas están propensas a cambiar hora y fecha</span></li>
                                        </ol>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
      
}