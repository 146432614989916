import React from 'react'
import '../css/header.css'
import Nav from './nav'
function Header(){    
    return(  
        <>   
        <header className="header fixed-top header-scrolled">                 
            <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
                <div className="col-md-12 row">
                    <div className="col-md-6">
                        <a href="./" className="text-center">
                            <img src='/img/logo_header.png' alt="" className="img-left"/>
                        </a>
                    </div>
                    <div className="col-md-6">
                        <a href="./" className="text-center">
                            <img src="/img/header-derecha.png" alt="Nature" className="img-rigth"/>  
                        </a>
                    </div>
                </div>             </div>  
            <section className="breadcrumbs">
                <div className="container">
                     <Nav/>
                </div>
            </section>         
        </header>        
        </> 
    )
}

export default Header;