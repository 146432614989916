import '../css/form_citas.css'
import { useParams } from "react-router-dom";
import React, { useState, useRef } from "react";
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import $ from 'jquery';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
export default function MyForm(){

    const { proceso } = useParams();

    const [spiner, setSpiner] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    let formData = new FormData(); 

    const keyCodigo = useRef(null);
    const [state, setState] = React.useState({
        id : proceso,
        fecha : new Date(),
        solicitud_id : 0,
        verForm : 0,
        tema : "",
        ruc : "",
        estb : "",
        proceso_info : "",
        cedula : "",
        codigo : "",
        titleMessage : "",
        bodyMessage : "",
        rs : "",
        nombre : "",
        correo : "",
        diaSeleccionado: 0
    })
    //const urlConsultarTemasprocesos = "/servicios/citas_temas_procesos_arcsa";
    /*const [itemsTemasProcesos, setItemsTemasProcesos] = useState([]); 
    
    useEffect(() => {
        //const pro = window.location.href.split("/");
         //función para consultar procesos
        let formData = new FormData();
           
        const fetchItemsTemasprocesosCitas = async () => {  
            formData.append('proceso', proceso);
                const requestOptions = {
                method: 'POST',
                body: formData
            };       
            const data = await fetch (urlConsultarTemasprocesos,requestOptions);
            const itemsTemasProcesos = await data.json();
            //setItemsTemasProcesos(itemsTemasProcesos.data[0]); 
            setItemsHoras(itemsTemasProcesos.data[1]); 
            setState({
                id : proceso,
                verForm : 0,
                solicitud_id : 0,
                tema: itemsTemasProcesos.tipo, 
                ruc : "",
                proceso_info : "",
                estb : "",
                cedula : "",
                titleMessage : "",
                bodyMessage : "",
                rs : "",
                nombre : "",
                correo : ""
            });
        }  
        fetchItemsTemasprocesosCitas();            
    }, []);*/

   

    const urlValidar = "/servicios/validar_codigo_cita_virtual";
    const handleValidarCodigo = async () => {
        const key = keyCodigo.current.value;
        setSpiner(true); 
        if(key === ""){
            setState({
                id : proceso,
                verForm : 0,
                solicitud_id : 0,
                tema: state.tema,
                ruc : "",
                estb : "",
                proceso_info : "",
                cedula : "",
                codigo : key,
                titleMessage : "Mensaje",
                bodyMessage : "Ingresar un código",
                rs : "",
                nombre : "",
                correo : "",
            });    
            setOpen(true); 
        }else{ 
            formData.append('key', key);
            formData.append('proceso', state.id);
                const requestOptions = {
                method: 'POST',
                body: formData
            };               
            const data = await fetch (urlValidar,requestOptions);
            const items = await data.json();
            if(items.error === 0){           
                setItemsHoras(items.data[1]);        
                setState({
                    id : state.id,
                    solicitud_id : items.id,
                    verForm : 1,
                    tema: state.tema,
                    ruc : items.ruc,
                    estb : items.establecimiento,
                    cedula : items.cedula,
                    codigo : key,
                    proceso_info : items.proceso_info,
                    titleMessage : "mensaje",
                    bodyMessage : "Recuerde que podrá separar una cita con un día de anticipación",
                    rs : items.razon_social,
                    nombre : items.nombre,
                    correo : items.correo
                });    
                setOpen(true); 
            }else{                    
                setState({
                    id : state.id,
                    verForm : 0,
                    solicitud_id : 0,
                    tema: state.tema,
                    ruc : "",
                    estb : "",
                    cedula : "",
                    proceso_info : "",
                    titleMessage : "Mensaje",
                    bodyMessage : items.mensaje,
                    rs : "",
                    nombre : "",
                    correo : ""
                });  
                setOpen(true);  
            }
        }       
        setSpiner(false); 
       // setOpen(true); 
        window.scrollTo(0, 400);
    }

    const handleClose = () => {
         var hora = $("input[name='hora']:checked").val();
        if(hora){
            setState({
                id : state.id,
                solicitud_id : state.solicitud_id,
                verForm : 1,
                tema: state.tema,
                ruc : state.ruc,
                estb : state.establecimiento,
                cedula : state.cedula,
                codigo : state.codigo,
                proceso_info : state.proceso_info,
                titleMessage : "Mensaje",
                bodyMessage : "",
                rs : state.rz,
                nombre : state.nombre,
                correo : state.correo,
                horaSeleccionada : hora
            });            
        }
        setOpen(false);
       // setOpenModalHorarios(false);
        window.scrollTo(0, 600);
    };

    function handleChangeTexto(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
    };

    const [itemsHoras, setItemsHoras] = useState([]); 

    const urlGuardarSolicitud = "/servicios/guardar_solicitud_reunion";
    const handleGuardarCita = async () => {
        setSpiner(true);  
        if( $('#checkAcepta').is(':checked') ) {
            var radioValue = true;
            if(radioValue){
                var hora = state.horaSeleccionada;
                if(hora > 0){
                    formData.append('proceso', state.id);                    
                    formData.append('solicitud_id', state.solicitud_id);
                    formData.append('servicio', radioValue);
                    formData.append('hora', hora);
                    const requestOptions = {
                        method: 'POST',
                        body: formData
                    };
                    const data = await fetch (urlGuardarSolicitud,requestOptions);
                    const items = await data.json();
                    if(items.error === 0){                   
                        setOpen(true);
                        setState({
                            titleMessage: items.titulo,
                            bodyMessage: items.mensaje
                        });   
                        setSpiner(false);
                        setTimeout(function() {                            
                            window.location.reload();
                        }, 5000); 
                    }else{                    
                        setState({
                            id : state.id,
                            solicitud_id : state.solicitud_id,
                            verForm : 1,
                            tema: state.tema,
                            ruc : state.ruc,
                            estb : state.establecimiento,
                            cedula : state.cedula,
                            proceso_info : state.proceso_info,
                            codigo : state.codigo,
                            titleMessage : "Mensaje",
                            bodyMessage : items.mensaje,
                            rs : state.rz,
                            nombre : state.nombre,
                            correo : state.correo
                        });  
                        setOpen(true);  
                    }
                }else{
                    setState({
                        id : state.id,
                        solicitud_id : state.solicitud_id,
                        verForm : 1,
                        tema: state.tema,
                        ruc : state.ruc,
                        estb : state.establecimiento,
                        cedula : state.cedula,
                        codigo : state.codigo,
                        proceso_info : state.proceso_info,
                        titleMessage : "Mensaje",
                        bodyMessage : "Debe seleccionar la hora para la reunión, dar click en los días en la sección horario para seleccionar la hora ",
                        rs : state.rz,
                        nombre : state.nombre,
                        correo : state.correo
                    }); 
                }                
            }else{
                setState({
                    id : state.id,
                    solicitud_id : state.solicitud_id,
                    verForm : 1,
                    tema: state.tema,
                    ruc : state.ruc, 
                    estb : state.establecimiento,
                    cedula : state.cedula,
                    codigo : state.codigo,
                    proceso_info : state.proceso_info,
                    titleMessage : "Mensaje",
                    bodyMessage : "Debe seleccionar el servicio o tema para la reunión",
                    rs : state.rz,
                    nombre : state.nombre,
                    correo : state.correo
                }); 
            }
        }else{
            setState({
                id : state.id,
                solicitud_id : state.solicitud_id,
                verForm : 1,
                tema: state.tema,
                ruc : state.ruc,
                estb : state.establecimiento,
                cedula : state.cedula,
                codigo : state.codigo,
                proceso_info : state.proceso_info,
                titleMessage : "Mensaje",
                bodyMessage : "Debe seleccionar el acuerdo de confidencialidad",
                rs : state.rz,
                nombre : state.nombre,
                correo : state.correo
            }); 
        }
        setSpiner(false);
        setOpen(true);
    }

    function handleClickdia(){
        var hora = $("input[name='hora']:checked").val();
        if(hora){
            setState({
                id : state.id,
                solicitud_id : state.solicitud_id,
                verForm : 1,
                tema: state.tema,
                ruc : state.ruc,
                estb : state.establecimiento,
                cedula : state.cedula,
                proceso_info : state.proceso_info,
                codigo : state.codigo,
                titleMessage : "Mensaje",
                bodyMessage : "",
                rs : state.rz,
                nombre : state.nombre,
                correo : state.correo,
                horaSeleccionada : hora
            });            
        }
    } 

    const urlCambiarFecha = "/servicios/consultar_dia_por_fecha";
    const onDateChange = (newDate) => {
        var date = newDate;
        var year = date.getFullYear();
        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;      
        fetchFecha(day, month, year);
    }

    const fetchFecha = async (dia, mes , anio) => { 
        formData.append('dia', dia);
        formData.append('mes', mes);
        formData.append('anio', anio);
        formData.append('key', state.codigo);
        formData.append('proceso', state.id);
        const requestOptions = {
            method: 'POST',
            body: formData
        };
        setSpiner(true);
        const data = await fetch (urlCambiarFecha,requestOptions);
        const items = await data.json();
        setItemsHoras(items.data[0]); 
        setSpiner(false);
    }    

    return (
        <>        
        <input id="procedo_id_catalogo" type="hidden" value="0" />
        <Dialog open={open}  onClose={handleClose}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{state.titleMessage}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {state.bodyMessage}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
                Cerrar
            </Button>
            </DialogActions>
        </Dialog>
        
        <Dialog open={spiner}   aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title text-center">Cargando</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description" className="text-center">
                <CircularProgress />
            </DialogContentText>
            </DialogContent>
        </Dialog>
        <input type="hidden" value={state.solicitud_id} id="solicitud_id"  onChange={handleChangeTexto}/>

        <div className="row">
            <div className="col-lg-12">          
                <fieldset>                    
                    <div className="alert alert-success alert-dismissable">
                        <div className="row text-center">
                            <div className="col-md-12">
                                <b>Citas Virtuales - ARCSA - {state.tema}  </b><br/> 
                                * Recuerde que para poder agendar una cita, primero deberá obtener una código de correspondencia *
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>         
            
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                        <>
                        <div className="col-md-5 form-group">
                            <b><label>Ingrese código de correspondencia</label></b>
                            <input id="txtValidarKey" placeholder="Ingrese código de correspondencia" ref={keyCodigo} className="form-control" />
                        </div>
                        <div className="form-group col-md-3">                                           
                            <label>Validar</label>
                            <br/>
                            <button type="submit" onClick={handleValidarCodigo} className="btn btn-primary btn-sm"><SearchIcon/></button>
                        
                        </div> 
                        </>
                        </div> 
                    </div>
                   
               
                    {
                        state.verForm === 1 &&
                        <>                                            
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12" >
                                    <b><p style={{fontWeight:"bold"}}>Información de Solicitud</p></b>
                                </div>
                                <div className="col-md-3 form-group">
                                    <label>Ruc:</label>
                                    <input  value={state.ruc} onChange={handleChangeTexto}  maxLength="13" id="ruc" name="ruc" type="text" placeholder="ruc" className="form-control" disabled/>
                                </div>
                                <div className="col-md-2 form-group">
                                    <label>Establecimiento:</label>
                                    <input value={state.estb} onChange={handleChangeTexto}   maxLength="3" id="estb" name="estb" type="text" placeholder="establecimiento" className="form-control" disabled />
                                </div>
                                <div className="col-md-7 form-group">
                                    <label>Razón Social:</label>
                                    <input value={state.rs} onChange={handleChangeTexto}   maxLength="3" id="rs" name="rs" type="text" placeholder="razón social" className="form-control" disabled />
                                </div>
                                <div className="col-md-3 form-group">
                                    <label>Cédula:</label>
                                    <input value={state.cedula} onChange={handleChangeTexto}  maxLength="10" id="cedula" name="cedula" type="text" placeholder="cédula" className="form-control" disabled/>
                                </div> 
                                <div className="col-md-5 form-group">
                                    <label>Nombre:</label>
                                    <input value={state.nombre} onChange={handleChangeTexto}  maxLength="10" id="nombre" name="nombre" type="text" placeholder="nombre" className="form-control" disabled/>
                                </div>     
                                <div className="col-md-4 form-group">
                                    <label>Correo:</label>
                                    <input value={state.correo} onChange={handleChangeTexto}  maxLength="10" id="correo" name="correo" type="text" placeholder="correo" className="form-control" disabled/>
                                </div> 
                                <div className="col-md-12 form-group">
                                    <label>Proceso:</label>
                                    <input value={state.proceso_info} onChange={handleChangeTexto}  maxLength="10" id="correo" name="proceso_info" type="text" placeholder="proceso_info" className="form-control" disabled/>
                                </div>                                    
                            </div>                                
                        </div>
                        <div className="col-md-12 form-group">  
                            <div className="row">                               
                                <div className="col-md-12 form-group">  
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <div className="row"> 
                                                <div className="col-md-8">
                                                    <b style={{fontWeight:"bold"}}>Horarios</b> </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 form-group"> 
                                            <Calendar
                                                width={100}
                                                height={280}
                                                tileHeight={35}
                                                style={{alignSelf: 'center'}}
                                                onChange={onDateChange}
                                                value={state.date}
                                                minDate={new Date()}
                                                showNeighboringMonth={false}
                                            />                                            
                                        </div>
                                        <div className="col-lg-8">
                                        <table className="table table-bordered table-responsive-xl">
                                                <thead>
                                                    <tr>
                                                        <th style={{textAlign:"center"}}>Desde</th>
                                                        <th style={{textAlign:"center"}}>Hasta</th>
                                                        <th style={{textAlign:"center"}}>Seleccionar</th>
                                                        <th style={{textAlign:"center"}}>Estado</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {                                                                  
                                                        itemsHoras.length > 0 ? (
                                                            itemsHoras.map((val, key) => (  
                                                                <tr id={key}>
                                                                    <td style={{textAlign:"center"}}>                                            
                                                                    {val.desde}                                          
                                                                    </td> 
                                                                    <td style={{textAlign:"center"}}>                                            
                                                                    {val.hasta}                                          
                                                                    </td> 
                                                                    {
                                                                        val.fecha_actual === val.fecha_hora ? (
                                                                            <>
                                                                            <td style={{textAlign:"center"}}>                                            
                                                                                    -                                  
                                                                            </td> 
                                                                            <td style={{textAlign:"center"}}>                                            
                                                                                <b>Ocupado</b>                             
                                                                            </td> 
                                                                            </>
                                                                        ):(
                                                                            val.estado_hora === '0' ? (
                                                                                <>
                                                                                <td style={{textAlign:"center"}}>                                            
                                                                                    <div className="form-check">
                                                                                        <input onClick={handleClickdia} className="form-check-input" type="radio" name="hora" id="" value={val.id} />
                                                                                        <label className="form-check-label" for="exampleRadios1">
                                                                                            
                                                                                        </label>
                                                                                    </div>                            
                                                                                </td> 
                                                                                <td style={{textAlign:"center"}}>                                            
                                                                                    <b>Libre</b>                             
                                                                                </td>                                                                        
                                                                                </>
                                                                            ):(
                                                                                <>
                                                                                <td style={{textAlign:"center"}}>                                            
                                                                                        -                                  
                                                                                </td> 
                                                                                <td style={{textAlign:"center"}}>                                            
                                                                                    <b>Ocupado</b>                             
                                                                                </td> 
                                                                                </>
                                                                            )  
                                                                        )
                                                                        
                                                                    }                                         
                                                                </tr>
                                                            ))
                                                            ):(
                                                                <tr>
                                                                    <td colSpan="4" style={{textAlign:"center"}}>                                            
                                                                        No hay citas disponibles                                            
                                                                    </td>                                        
                                                                </tr>                            
                                                            ) 
                                                                                    
                                                    }
                                                </tbody>
                                            </table>                                                                                 
                                        </div>
                                    </div>
                                </div>                 
                                <div className="col-lg-12">
                                    <div className="alert alert-success alert-dismissable">
                                        <section className="param checkboxs">
                                            <label className="label required">De acuerdo a los requerimientos de la Ley 
                                            1581 de 2012 y a lo establecido en la política de tratamiento y 
                                            Protección de datos personales, mediante el registro de sus datos 
                                            personales en el presente formulario usted autoriza al ARCSA para 
                                            la recolección, almacenamiento y uso de los mismos con la finalidad 
                                            de adelantar el trámite de su solicitud.</label>
                                                <div className="form-check">
                                                    <input  className="form-check-input" type="checkbox" name="checkAcepta" id="checkAcepta" value="option1"/>
                                                    <label className="form-check-label" for="exampleRadios1">
                                                    Si
                                                    </label>
                                                </div>
                                        </section>
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <div className="col-md-12 text-center">
                                        <button type="submit" onClick={handleGuardarCita}  className="btn btn-primary btn-sm">Enviar Solicitud</button>
                                    </div>
                                </div> 
                            </div> 
                        </div> 
                        </>
                    }
                   
                </div>
            </div>
        </div>
        </>
    );
      
}