// Import types
import {
  INPUT_SUCCESS,
  INPUT_FAIL,
  SESSION_SUCCESS,
  SESSION_FAIL,
  MESSAGE_SUCCESS,
  SESSION_SELECT,
  MESSAGE_FAIL,
} from "../actions/types";

import FaceIcon from '@material-ui/icons/Face';
import AndroidIcon from '@material-ui/icons/Android';

// Initial state
const initialState = {
  messages: [],
};

// Switch statement - update state
export const fn = (state = initialState, action) => {
  const { type, payload } = action;
  let { messages } = state;

  switch (type) {
    case INPUT_SUCCESS:
      messages = [...messages, { message: payload, type: "user", op: "Ciu", tipo: "Ciudadano", icon: <FaceIcon/> }];
      return {
        ...state,
        messages,
      };
    case INPUT_FAIL:
      return {
        ...state,
      };
    case SESSION_SUCCESS:
      messages = [...messages, { message: payload, type: "bot", tipo: "Archie", op: "Ini", icon: <AndroidIcon/> }];
      return {
        ...state,
        messages,
      };
    case SESSION_SELECT:
      messages = [...messages, { message: payload, type: "bot", tipo: "Archie", op: "Sel", icon: <AndroidIcon/> }];
      return {
        ...state,
        messages,
      };
    case SESSION_FAIL:
      return {
        ...state,
      };
    case MESSAGE_SUCCESS:
      messages = [...messages, { message: payload, type: "bot",op: "respArcsa", tipo: "Archie", icon: <AndroidIcon/> }];
      return {
        ...state,
        messages,
      };
    case MESSAGE_FAIL:
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};


export default fn;