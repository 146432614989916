import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './css/global.css';
import Header from './master/header'
import Main from './master/main'
import Footer from './master/footer'
import Faq from './contenido/faq'
import Citas from './contenido/citas'
import CitaTemas from './contenido/cita_temas'
import EstadoCita from './contenido/estado_cita'
import Correspondencia from './contenido/correspondencia'
import CitaNuevaSolicitud from './contenido/cita_nueva_solicitud'
import Correspondencia_solicitud from './contenido/correspondencia_solicitud'
import Correspondencia_sin_tramite from './contenido/correspondencia_sin_solicitud'
import ChatBot from './contenido/chatbot'
import NotFound from './master/notfound';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'

function App() {
  return (
    <>
      <Router>
        <ChatBot />  
        <Header />
        <Switch>
          <Route path = '/' exact component={Main}/>
          <Route path = '/faq' component={Faq}/>
          <Route path = '/citas' component={Citas}/>
          <Route path = '/correspondencia' component={Correspondencia}/>
          <Route path = '/correspondencia-tramite' component={Correspondencia_solicitud}/>
          <Route path = '/correspondencia-general' component={Correspondencia_sin_tramite}/>
          <Route path = '/citas-temas' component={CitaTemas}/>
          <Route path = '/citas-estado' component={EstadoCita}/>
          <Route path = '/citas-:proceso' component={CitaNuevaSolicitud}/>
          <Route component={NotFound} />
        </Switch>    
        <Footer />
      </Router>      
    </>
  );
}

export default App;
