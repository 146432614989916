import React, { useState, useEffect, useRef } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from '@material-ui/icons/Search';
//import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function MyForm(){
    const [itemsProceso, setItemsProceso] = useState([]); 
    useEffect(() => {
        let formData = new FormData();
         //función para consultar procesos
           
        const fetchItemsProcesos = async () => {
            formData.append('arcsa', '');
            const requestOptions = {
            method: 'POST',
            body: formData
            };      
          const data = await fetch (urlProcesos,requestOptions);
          const itemsProceso = await data.json();
          setItemsProceso(itemsProceso.data[3]); 
        }  
        fetchItemsProcesos();
    }, []);
    const [open, setOpen] = React.useState(false);
    const [info, setInfo] = React.useState(false);
    const [spiner, setSpiner] = React.useState(false);
    const keyValidar = useRef(null);
    const urlValidar = "servicios/validar_info";
    const urlProcesos = "servicios/aplicativos_arcsa";
    const urlValidarKey = "servicios/consultar_key_arcsa";
    const urlGeneraCodigo = "servicios/generar_codigo_arcsa";
    const urlValidamosCodigo = "servicios/validar_codigo_arcsa";
    const urlingresarSolicitud = "servicios/ingresar_correspondencia_arcsa";    
    let formData = new FormData();

    const [state, setState] = React.useState({
        disabled : false,
        titleMessage: "",
        solicitud_id: 0,
        linea: "",
        bodyMessage: "",
        ruc: "",
        estb: "",
        cedula: "",
        select: 0,
        texto: "",
        proceso: "",
        correo: "",
        textoObse: "",
        validarKeyProceso: 0,
        validarKeyCorreo: 0,
        codigo: "",
        key : ""
    })

    
    const handleClose = () => {
        setOpen(false);
    };
    
    // función para validar solo números 
    function handleChange(evt) {
        const value = evt.target.value.replace(/\D/g, "");
        setState({
            ...state,
            [evt.target.name]: value
        });
    };

    function handleChangeTexto(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
    };

    
     
     
    // función para validar ruc y cédula
    const [validarRest, setValidarRest] = useState([]);
    const handleValidar = async () => {
        if(state.ruc === ""){
            setOpen(true);
            setState({texto : "" ,
                select : 0, 
                proceso : "",
                titleMessage: 'Advertencia',
                bodyMessage: "Ingresar número de Ruc",
                ruc : state.ruc ,
                estb : state.estb,
                cedula : state.cedula
            });
        }else{
            if(state.estb === 0){
                setOpen(true);
                setState({texto : "" ,
                    select : 0, 
                    proceso : "",
                    titleMessage: 'Advertencia',
                    bodyMessage: "Ingresar número de establecimiento",
                    ruc : state.ruc ,
                    estb : state.estb,
                    cedula : state.cedula
                });
            }else{
                if(state.cedula === ""){
                    setOpen(true);
                    setState({texto : "" ,
                        select : 0, 
                        proceso : "",
                        titleMessage: 'Advertencia',
                        bodyMessage: "Ingresar número de cédula",
                        ruc : state.ruc ,
                        estb : state.estb,
                        cedula : state.cedula
                    });
                }else{
                    setSpiner(true);        
                    formData.append('ruc', state.ruc);
                    formData.append('estb', state.estb);
                    formData.append('cedula', state.cedula);
                    const requestOptions = {
                        method: 'POST',
                        body: formData
                    };
                    const resp = await fetch (urlValidar, requestOptions);       
                    
                    const validarRest = await resp.json();
                    if(validarRest.error === 0){                        
                        setValidarRest(validarRest.data); 
                        setOpen(true);
                        setState({texto : "" ,
                            select : 0, 
                            proceso : "",
                            titleMessage: validarRest.titulo,
                            bodyMessage: validarRest.mensaje,
                            ruc : state.ruc ,
                            estb : state.estb,
                            cedula : state.cedula
                        });
                        window.scrollTo(0, 200);
                    }else{
                        setValidarRest(validarRest.data); 
                        setOpen(true);
                        setState({texto : "" ,
                            select : 0, 
                            proceso : "",
                            titleMessage: validarRest.titulo,
                            bodyMessage: validarRest.mensaje,
                            ruc : state.ruc ,
                            estb : state.estb,
                            cedula : state.cedula
                        });
                    }
                    setSpiner(false);
                }
            }
        }
        
    }
    //función mostrar id validar proceso 
    const urlLienaProceso = "servicios/consultar_procesos_linea";
    const [itemsLineasProceso, setItemsLineasProceso] = useState([]);   
    const handledChangeProceso = async (evt) => {
        setSpiner(true); 
        formData.append('linea', evt.target.value);
        formData.append('ruc', state.ruc);
        formData.append('est', state.estb);
        formData.append('texto', evt.nativeEvent.target[evt.target.selectedIndex].text);
        
        const requestOptions = {
            method: 'POST',
            body: formData
        };      
        const data = await fetch (urlLienaProceso,requestOptions);
        const itemsLineasProceso = await data.json();
        const textoSelect = "Código de " + evt.nativeEvent.target[evt.target.selectedIndex].text;
        if(itemsLineasProceso.error === 0){  
            setItemsLineasProceso(itemsLineasProceso.data[0]);            
            
            setState({texto : textoSelect ,
                select : 0, 
                linea : textoSelect,
                proceso : evt.target.value,
                ruc : state.ruc ,
                estb : state.estb,
                cedula : state.cedula});
        }else{
            setState({texto : textoSelect ,
                select : 0, 
                linea : textoSelect,
                proceso : evt.target.value,
                ruc : state.ruc ,
                titleMessage: "Mensaje",
                bodyMessage: itemsLineasProceso.mensaje,
                estb : state.estb,
                cedula : state.cedula});
                setOpen(true);
        }
        setSpiner(false); 
    }

    const handledChangeProcesoLinea = async (evt) => {
        const textoSelect = "Su selección: " + evt.nativeEvent.target[evt.target.selectedIndex].text;
        if(evt.target.value === "0" | evt.target.value === "99"){
            setState({texto : textoSelect ,
                 select : 0, 
                 linea : state.linea,
                 proceso : evt.target.value,
                 ruc : state.ruc ,
                 estb : state.estb,
                 titleMessage: "Mensaje",
                bodyMessage: "Seleccione un proceso",
                 cedula : state.cedula});
                setOpen(true);
            
            }else{
                 setState({texto : textoSelect ,
                 select : 1, 
                 linea : state.linea,
                 proceso : evt.target.value,
                 ruc : state.ruc ,
                 estb : state.estb,
                 cedula : state.cedula});
            }
       
    }
    

    //validamos el código ingresado para el proceso
    const handleValidarCodigoProceso = async () => {
        formData.append('proceso', state.proceso);
        formData.append('key', keyValidar.current.value);
        formData.append('ruc', state.ruc);
        formData.append('estb', state.estb);
        formData.append('cedula', state.cedula);
        const requestOptions = {
            method: 'POST',
            body: formData
        };
        setSpiner(true);
        const resp = await fetch (urlValidarKey,requestOptions);        
        const validarRest = await resp.json();        
        if(validarRest.error === 0){                      
            setOpen(true);
            setState({
                texto : state.texto,
                select : 1,
                key : keyValidar.current.value,
                proceso : state.proceso,
                titleMessage: validarRest.titulo,
                bodyMessage: validarRest.mensaje,
                ruc : state.ruc ,
                estb : state.estb,
                cedula : state.cedula,
                validarKeyProceso : 1
            });
            window.scrollTo(0, 400); 
        }else{
            setValidarRest(validarRest.data); 
            setOpen(true);
            setState({texto : "" ,
                select : 0,
                proceso : "",
                titleMessage: validarRest.titulo,
                bodyMessage: validarRest.mensaje,
                ruc : state.ruc ,
                estb : state.estb,
                cedula : state.cedula,
                validarKeyProceso : 0
            });
        }
        setSpiner(false);       
    }


    //validamos el código y correo
    const handleGenerarCodigo = async () => {
       // const textoSelect = state.proceso;
       // const key = keyValidar.current.value;
        const correo = state.correo;
        //const ruc = state.ruc;
        //const estb = state.estb;
        //const cedula = state.cedula;
        formData.append('proceso', state.proceso);
        formData.append('key', keyValidar.current.value);
        formData.append('ruc', state.ruc);
        formData.append('estb', state.estb);
        formData.append('cedula', state.cedula);
        formData.append('correo', state.correo);
        const requestOptions = {
            method: 'POST',
            body: formData
        };
        setSpiner(true);
        // Validamos si el formato del Email es correcto 
        if(typeof correo === "undefined" | correo === ""){
            setState({
                texto : state.texto,
                select : 1,
                key : state.key,
                proceso : state.proceso,
                titleMessage: "Validación de Información",
                bodyMessage: "Ingresar un correo electrónico",
                ruc : state.ruc ,
                correo : state.correo,
                obs : "",
                estb : state.estb,
                cedula : state.cedula,
                validarKeyProceso : 1
            });
            setOpen(true);
        }else{
            const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;       
                if (!emailRegex.test(correo)) {
                    setState({
                        texto : state.texto,
                        select : 1,
                        key : state.key,
                        proceso : state.proceso,
                        titleMessage: "Validación de Información",
                        bodyMessage: "Ingresar un correo válido",
                        ruc : state.ruc ,
                        correo : state.correo,
                        obs : "",
                        estb : state.estb,
                        cedula : state.cedula,
                        validarKeyProceso : 1
                    });
                    setOpen(true);
                }else{
                    setSpiner(true);
                    const resp = await fetch (urlGeneraCodigo,requestOptions);                    
                    const validarRest = await resp.json();
                    if(validarRest.error === 0){
                        setState({
                            texto : state.texto,
                            select : 1,
                            solicitud_id : validarRest.id,
                            codigo : state.codigo,
                            key : state.key,
                            correo : state.correo,
                            proceso : state.proceso,
                            titleMessage: validarRest.titulo,
                            bodyMessage: validarRest.mensaje,
                            ruc : state.ruc ,
                            estb : state.estb, 
                            cedula : state.cedula,
                            validarKeyProceso : 1,
                            validarKeyCorreo : 0
                        });
                        setOpen(true);        
                    }else{
                        setState({
                            texto : state.texto,
                            select : 1,
                            solicitud_id : 0,
                            codigo : state.codigo,
                            key : state.key,
                            correo : state.correo,
                            proceso : state.proceso,
                            titleMessage: validarRest.titulo,
                            bodyMessage: validarRest.mensaje,
                            ruc : state.ruc ,
                            estb : state.estb, 
                            cedula : state.cedula,
                            validarKeyProceso : 1,
                            validarKeyCorreo : 0
                        });
                        setOpen(true); 
                    }             
                    
                }
        }
        setSpiner(false);       
    }

    //validamos correo, código e ingresamos la solicitud de correspondencia con estado 0 y validación código 0
    const handleValidarCodigo = async () => {
        const solicitud_id = state.solicitud_id;
        const codigo = state.codigo;
        // Validamos si el formato del Email es correcto 
        if(typeof codigo === "undefined" | codigo === ""){
            setState({
                texto : state.texto,
                select : 1,
                key : state.key,
                proceso : state.proceso,
                titleMessage: "Validación de Información",
                bodyMessage: "Recuerde escribir el código enviado.",
                ruc : state.ruc ,
                correo : state.correo,
                obs : state.textoObse,
                estb : state.estb,
                cedula : state.cedula,
                validarKeyProceso : 1
            });
            setOpen(true);
        }else{
            setSpiner(true);
            formData.append('solicitud_id', solicitud_id);
            formData.append('codigo', codigo);
            const requestOptions = {
                method: 'POST',
                body: formData
            };
            const resp = await fetch (urlValidamosCodigo,requestOptions);
            
            const validarRest = await resp.json();
            if(validarRest.error === 1){
                setState({
                    texto : state.texto,
                    select : 1,
                    solicitud_id : state.solicitud_id,
                    codigo : state.codigo,
                    key : state.key,
                    proceso : state.proceso,
                    titleMessage: validarRest.titulo,
                    bodyMessage: validarRest.mensaje,
                    ruc : state.ruc ,
                    estb : state.estb, 
                    cedula : state.cedula,
                    validarKeyProceso : 1,
                    validarKeyCorreo : 0
                });
                setOpen(true);
                window.scrollTo(0, 400);         
            }else{
                setState({
                    texto : state.texto,
                    select : 1,
                    solicitud_id : state.solicitud_id,
                    codigo : state.codigo,
                    key : state.key,
                    proceso : state.proceso,
                    titleMessage: validarRest.titulo,
                    bodyMessage: validarRest.mensaje,
                    ruc : state.ruc ,
                    estb : state.estb, 
                    cedula : state.cedula,
                    validarKeyProceso : 1,
                    validarKeyCorreo : 1
                });

                window.scrollTo(0, 400);
            }  
        }  
        setSpiner(false);      
    }

    const handleModalInfo = async () => {
        setInfo(true);
    }

    const handleCloseInfo = async () => {
        setInfo(false);
    }

    //enviamos correspondencia
    const handleEnviarCorrespondencia = async () => {
        const id = state.solicitud_id;
        const obs = escape(state.textoObse);
        formData.append('id', id);
        formData.append('obs', obs);
        const requestOptions = {
            method: 'POST',
            body: formData
        };
        // Validamos si el formato del Email es correcto 
        if(typeof obs === "undefined" | obs === ""){
            setState({
                titleMessage: "Validación de Información",
                bodyMessage: "Ingresar una observación",
                select : 1,
                solicitud_id : state.solicitud_id,
                codigo : state.codigo,
                key : state.key,
                proceso : state.proceso,                
                ruc : state.ruc ,
                estb : state.estb, 
                cedula : state.cedula,
                validarKeyProceso : 1,
                validarKeyCorreo : 1
            });
            setOpen(true);
        }else{
            setSpiner(true);
            const resp = await fetch (urlingresarSolicitud,requestOptions);            
            const validarRest = await resp.json();
            if(validarRest.error === 0){
                setOpen(true);
                setState({
                    titleMessage: validarRest.titulo,
                    bodyMessage: validarRest.mensaje
                });   
                setSpiner(false);
                setTimeout(function() {                            
                    window.location.reload();
                }, 5000);         
            }else{

            } 
        }       
    }    
      
    return (
        <>
        {/*Modal info*/}

        <Dialog open={info}  onClose={handleClose}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Términos de Correspondencia</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <>
                
                <strong><i class="fas fa-book mr-1"></i>Referencia:</strong>

                <p class="text-muted">
                corresponde al código del trámite y/o documento que previamente ingreso o se encuentra en curso con ARCSA.                </p>

                <hr/>

                <strong><i class="fas fa-map-marker-alt mr-1"></i>Categoría Tramite:</strong>

                <p class="text-muted">corresponde al grupo de procesos o tramites que nuestros usuarios pueden gestionar cono ARCSA  </p>

                <hr/>

                <strong><i class="fas fa-pencil-alt mr-1"></i>Proceso:</strong>

                <p class="text-muted">corresponde al trámite o consulta especifica referente a un trámite cual ha sido catalogada para poder responder canalizar su consulta con el personal idóneo de ARCSA.  </p>


                <hr/>

                <strong><i class="far fa-file-alt mr-1"></i>Correo electrónico: </strong>

                <p class="text-muted">
                corresponde al buzón declarado por el solicitante en el cual recibirá códigos de validaciones para el ingreso de sus interacciones y las respuestas por parte de ARCSA
                </p>

                <hr/>

                <strong><i class="far fa-file-alt mr-1"></i>Código de validación: </strong>

                <p class="text-muted">
                corresponde a un pin de validación previa a la correspondencia que se remite al correo electrónico declarado para la interacción del solicitante y la confirmación de buzón de destino para las respuestas que emitidas por ARCSA.                </p>


                <hr/>

                <strong><i class="far fa-file-alt mr-1"></i>Detalle de Información: </strong>

                <p class="text-muted">
                Corresponde a la consulta especifica en donde el solicitante proporciona toda la información relacionada con la Referencia ingresada, considerando que debe ser clara y detallada para el correcto entendimiento y atención de la misma.                </p>
               
               
                <hr/>

                <p class="text-muted">
                <b>Importante:</b> las correspondencias ingresadas por el ciudadano, con para interacciones de un trámite en curso y catalogado por ARCSA, en donde deben seleccionar la categoría y proceso debido de la misma manera que la referencia ingresada debe existir. Y el detalle de la correspondencia debe indicar clara y objetivamente el problema y/o pregunta a realizar. En caso de que estos elementos no tengan la pertinencia la correspondencia no podrá ser válida.
                </p>
               
                </>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseInfo} color="primary" autoFocus>
                Cerrar
            </Button>
            </DialogActions>
        </Dialog>
         
          {/*Modal mensaje*/}
        <Dialog open={open}  onClose={handleClose}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{state.titleMessage}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {state.bodyMessage}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
                Cerrar
            </Button>
            </DialogActions>
        </Dialog>

        {/*Modal Cargadno*/}
        <Dialog open={spiner}   aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title text-center">Consultando información</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description" className="text-center">
                <CircularProgress />
            </DialogContentText>
            </DialogContent>
        </Dialog>
        <input type="hidden" value={state.solicitud_id} id="solicitud_id"  onChange={handleChangeTexto}/>
        <div className="row">
            <div className="col-lg-12 form-group">          
                <fieldset>                    
                    <div className="alert alert-success alert-dismissable">
                        <div className="row text-justify">
                            <div className="col-md-12">
                                <b>Estimado Usuario:</b> la correspondencia es un servicio de interacción objetiva entre un solicitante y personal técnico de la agencia para facilitar el análisis de lo reportado acerca de un tramite previamente ingresado con ARCSA 
                                &nbsp;&nbsp;&nbsp;<a href="#!" onClick={handleModalInfo}  className="btn btn-info">Ayuda <InfoIcon/></a>                     
                            </div>
                        </div>
                    </div>
                </fieldset> 
            </div>
            <div className="col-lg-12 form-group">          
                <fieldset>                    
                    <div className="alert alert-secondary alert-dismissable">
                        <div className="row text-justify">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12" >
                                        <b>Ingrese información del remitente</b> 
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label>Ruc:</label>
                                        <input  value={state.ruc} onChange={handleChange} maxLength="13" id="ruc" name="ruc" type="text" placeholder="Ruc" className="form-control"/>
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label>Establecimiento:</label>
                                        <input value={state.estb}  onChange={handleChange} maxLength="3" id="estb" name="estb" type="text" placeholder="Establecimiento" className="form-control"/>
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label>Cédula:</label>
                                        <input value={state.cedula} onChange={handleChange}  maxLength="10" id="cedula" name="cedula" type="text" placeholder="Cédula" className="form-control"/>
                                    </div>                                    
                                </div>                                
                            </div>
                            <div className="form-group col-md-12">
                                <div className="col-md-12 text-center">
                                    <button onClick={handleValidar}  type="submit" className="btn btn-primary btn-sm">Verificar información</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">                       
                    </div> 
                </fieldset>                    
                
            </div>      
            {
                validarRest.length > 0 &&                      
                    <div className="col-lg-12 form-group">          
                        <fieldset>                                                                                         
                            <table className="table table-bordered table-responsive-xl">
                                <thead>
                                    <tr>
                                        <th className="table-primary" colSpan="4"><b style={{fontWeight:"bold"}}>Información del solicitante de correspondencia</b></th>
                                    </tr>
                                    <tr>
                                        <th style={{textAlign:"center"}}>Ruc</th>
                                        <th style={{textAlign:"center"}}>Razón Social</th>
                                        <th style={{textAlign:"center"}}>Cédula</th>
                                        <th style={{textAlign:"center"}}>Nombre</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        validarRest.map((val, key) =>( 
                                            <tr id={key}>
                                                <td style={{textAlign:"center"}}>{val.ruc}</td>
                                                <td style={{textAlign:"center"}}>{val.razon}</td>
                                                <td style={{textAlign:"center"}}>{val.cedula}</td>
                                                <td style={{textAlign:"center"}}>{val.nombre}</td>
                                            </tr>
                                        ))
                                    }                                                            
                                </tbody>                                            
                            </table> 
                        </fieldset>   
                    </div>                               
            }     
            {
                validarRest.length > 0 &&
                <div className="col-lg-12 form-group">          
                    <fieldset className="form-group"> 
                        <div className="form-group">
                            <b style={{fontWeight:"bold"}}>Seleccione Tipo de correspondencia:</b>
                        </div>
                        <fieldset> 
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <label>Categoría de trámite ARCSA:</label>                                                                        
                                        <select onChange={handledChangeProceso} className="form-control form-group">
                                            <option value="0">Seleccionar categoría</option>
                                            {
                                                itemsProceso.length > 0 ? (
                                                    itemsProceso.map(val => ( 
                                                        <option value={val.id}>{val.title}</option>
                                                    ))
                                                ):(
                                                    <option value="99">No existen opciones</option>                                            
                                                )                                
                                            }    
                                        </select>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Seleccionar trámite específico:</label>                                                                        
                                        <select onChange={handledChangeProcesoLinea} className="form-control form-group">
                                            <option value="0">Seleccionar opción</option>
                                            {
                                                itemsLineasProceso.length > 0 ? (
                                                    itemsLineasProceso.map(val => ( 
                                                        <option value={val.id}>{val.descripcion}</option>
                                                    ))
                                                ):(
                                                    <option value="99">No existen opciones</option>                                            
                                                )                                
                                            }    
                                        </select>
                                    </div>
                                    {
                                        state.select > 0 &&
                                        <>
                                        <div className="col-md-12 form-group">
                                            <b><label>{state.texto}</label></b>
                                        </div>
                                        <div className="col-md-6 form-group">   
                                            <label>Ingresar <b>Referencia</b> de trámite en Arcsa</label>                                         
                                            <input id="txtValidarKey" placeholder='código de trámite en arcsa' ref={keyValidar} className="form-control" />
                                        </div>
                                        <div className="form-group col-md-6">                                           
                                            <label>Verificar</label>
                                            <br/>
                                            <button type="submit" onClick={handleValidarCodigoProceso} className="btn btn-primary btn-sm"><SearchIcon/></button>
                                        
                                        </div> 
                                        </>
                                    }
                                    
                                </div>
                            </div>
                        </fieldset> 
                        {
                            state.validarKeyProceso > 0 &&
                                state.validarKeyProceso === 1 ? (
                                    <>
                                    <div className="form-group">
                                        <b style={{fontWeight:"bold"}}> Para validar su correo electrónico enviaremos un código de confirmación, recuerde ingresar un correo electrónico al cual tenga acceso.</b>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-lg-4 form-group">                                             
                                            <label><b>Ingresar correo electrónico del solicitante </b></label>                               
                                            <input onChange={handleChangeTexto} value={state.correo}  maxLength="100" id="correo" name="correo" className="form-control" />
                                        </div>
                                        <div className="form-group col-md-2">                                           
                                            <label>Enviar código</label>
                                            <br/>
                                            <button type="submit" onClick={handleGenerarCodigo} className="btn btn-primary btn-sm"><MailOutlineIcon/></button>
                                        </div> 
                                        <div className="col-lg-4 form-group"> 
                                            <label><b>Ingresar el código enviado a su correo </b> </label>                               
                                            <input onChange={handleChangeTexto} value={state.codigo}  maxLength="100" id="codigo" name="codigo" className="form-control" />
                                        </div>
                                        <div className="form-group col-md-2">                                           
                                            <label>Validar código</label>
                                            <br/>
                                            <button type="submit" onClick={handleValidarCodigo} className="btn btn-primary btn-sm"><CheckCircleOutlineIcon/></button>
                                        </div> 
                                    </div>
                                   
                                    </>
                                ):(
                                    state.validarKeyProceso === 2 &&
                                    <>
                                    <div className="form-group col-md-12">
                                        <div className="col-md-12 text-center">
                                            <label>El código ingresado no es el enviado a su correo electrónico {state.correo} </label>
                                        </div>
                                    </div> 
                                    </>
                                )                       
                        }
                        {
                            state.validarKeyCorreo > 0 &&
                                state.validarKeyCorreo === 1 ? (
                                     <>
                                    <div className="col-lg-12 form-group"> 
                                        <div className="row">  
                                            <label><b>Detalle la información en relación con la referencia ingresada</b></label>                                 
                                            <textarea rows="5" cols="50" onChange={handleChangeTexto} className="form-control" value={state.textoObse}  maxLength="5000" id="textoObse" name="textoObse"></textarea>
                                        </div>  
                                    </div>  
                                    <div className="form-group col-md-12">
                                        <div className="col-md-12 text-center">
                                            <button type="submit" onClick={handleEnviarCorrespondencia}  className="btn btn-success btn-sm">Enviar Solicitud</button>
                                        </div>
                                    </div> 
                                    </>
                                ):(
                                    state.validarKeyCorreo === 2 &&
                                    <>
                                    <div className="form-group col-md-12">
                                        <div className="col-md-12 text-center">
                                            <label>No pudimos validar tu código, vrificar si escribió bien su correo electrónico o revisar la bandeja de Spam</label>
                                        </div>
                                    </div> 
                                    </>
                                ) 
                        }
                    </fieldset> 
                </div>                                    
            } 
             
        </div>
        </>
    );
      
}