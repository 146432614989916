import React from "react";
import FormCitaNueva from '../form/form_cita_temas'

    function CitaTemas(){
        return(
            <main id="main"> 
                <section className="section">   
                    <div className="col-md-12">
                        <div className="sidebar">
                            <div className="sidebar-item categories img-back" >
                                <div className="container"><br/>                                                                         
                                    <FormCitaNueva/>                                       
                                </div>                    
                            </div>
                            <br/>
                        </div>
                    </div>
                </section>
            </main>
        )
    }

export default CitaTemas;