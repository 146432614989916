import '../css/form_citas.css'
import React, { useState, useRef } from "react";
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import SearchIcon from '@material-ui/icons/Search';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
export default function MyForm(){

    const [state, setState] = React.useState({
        id : 0,
        titleMessage : "",
        bodyMessage : "",
        tablaInfo : 0,
        codigo_cita : ""
    })
    const [spiner, setSpiner] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const keyCodigo = useRef(null);
    let formData = new FormData(); 

    const handleClose = () => {
        setOpen(false);
        window.scrollTo(0, 600);
    };


    const [items, setItems] = useState([]); 
    const urlValidar = "servicios/consultar_info_cita";
    const handleValidarCodigo = async () => {
        const key = keyCodigo.current.value;
        setSpiner(true); 
        if(key === ""){
            setState({
                id : 0,
                titleMessage : "Mensaje",
                bodyMessage : "Ingresar un código",
                tablaInfo : 0,
                codigo_cita : ""
            });    
            setOpen(true); 
        }else{             
            formData.append('codigo', key);
            const requestOptions = {
                method: 'POST',
                body: formData
            }; 
            const data = await fetch (urlValidar,requestOptions);
            const items = await data.json();            
            if(items.error === 0){    
                setItems(items.data[0]);                                
                setState({
                    id : items.id,
                    titleMessage : "mensaje",
                    bodyMessage : items.mensaje,
                    tablaInfo : 1,
                    codigo_cita : key
                });    
            }else{                    
                setState({
                    id : 0,
                    titleMessage : "mensaje",
                    bodyMessage : items.mensaje,
                    tablaInfo : 0,
                    codigo_cita : ""
                });  
                setOpen(true);  
            }
        }       
        setSpiner(false); 
       // setOpen(true); 
        window.scrollTo(0, 400);
    }

    const urlEnviarCorreo = "servicios/enviar_correo_info";
    const handleEnviarCorreo = async () => {
        setSpiner(true);
        formData.append('id', state.id);
        const requestOptions = {
            method: 'POST',
            body: formData
        };
        const data = await fetch (urlEnviarCorreo,requestOptions);
        const items = await data.json();
        if(items.error === 0){                                   
            setOpen(true);
            setState({
                titleMessage: items.titulo,
                bodyMessage: items.mensaje
            });   
            setSpiner(false);
            setTimeout(function() {                            
                window.location.reload();
            }, 5000);     
        }else{                    
            setState({
                id : 0,
                titleMessage : "mensaje",
                bodyMessage : items.mensaje,
                tablaInfo : 1,
                codigo_cita : state.codigo_cita
            });  
            setSpiner(false);
            setOpen(true);  
        }
    }

    return (
        <> 
        <Dialog open={open}  onClose={handleClose}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{state.titleMessage}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {state.bodyMessage}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
                Cerrar
            </Button>
            </DialogActions>
        </Dialog>
        {/*Modal Cargadno*/}
        <Dialog open={spiner}   aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title text-center">Cargando</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description" className="text-center">
                <CircularProgress />
            </DialogContentText>
            </DialogContent>
        </Dialog>

        <div className="row">
            <div className="col-lg-12">          
                <fieldset>                    
                    <div className="alert alert-success alert-dismissable">
                        <div className="row text-center">
                            <div className="col-md-12">
                                * Recuerde que para poder agendar una cita, primero deberá obtener una código de correspondencia *
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>         
            
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                        <>
                        <div className="col-md-7 form-group">
                            <label>Ingrese código de la cita que fue enviado a su correo electrónico</label>
                            <input id="txtValidarKey" placeholder="Ingrese código de su cita" ref={keyCodigo} className="form-control" />
                        </div>
                        <div className="form-group col-md-3">                                           
                            <label>Validar</label>
                            <br/>
                            <button type="submit" onClick={handleValidarCodigo}  className="btn btn-primary btn-sm"><SearchIcon/></button>
                        
                        </div> 
                        </>
                        </div> 
                    </div>     
                    {
                       state.tablaInfo === 1 && 
                       <div className="col-md-12 form-group">  
                            <div className="row">
                                <div className="col-lg-12 form-group">
                                    <table className="table table-bordered table-responsive-xl">
                                        <thead>
                                            <tr>
                                                <th className="table-active" colSpan="7">
                                                    <b>Información de su cita # { state.codigo_cita } </b>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th style={{textAlign:"center"}}>Ruc</th>
                                                <th style={{textAlign:"center"}}>Establecimiento</th>
                                                <th style={{textAlign:"center"}}>Cedula</th>
                                                <th style={{textAlign:"center"}}>Nombres</th>
                                                <th style={{textAlign:"center"}}>Fecha</th>
                                                <th style={{textAlign:"center"}}>Hora</th>
                                                <th style={{textAlign:"center"}}>Reenviar correo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {                                                                  
                                                items.length > 0 ? (
                                                    items.map(val => ( 
                                                        <tr>
                                                            <td style={{textAlign:"center"}}>                                            
                                                                {val.ruc}                                          
                                                            </td>
                                                            <td style={{textAlign:"center"}}>                                            
                                                                {val.establecimiento}                                          
                                                            </td>
                                                            <td style={{textAlign:"center"}}>                                            
                                                                {val.cedula}                                          
                                                            </td>
                                                            <td style={{textAlign:"center"}}>                                            
                                                                {val.nombre}                                          
                                                            </td>
                                                            <td style={{textAlign:"center"}}>                                            
                                                                {val.fecha_cita}                                          
                                                            </td>
                                                            <td style={{textAlign:"center"}}>                                            
                                                                {val.hora}                                          
                                                            </td>
                                                            <td style={{textAlign:"center"}}>                                            
                                                                <a href="#!" onClick={handleEnviarCorreo} ><MailOutlineIcon/> </a>                                      
                                                            </td>
                                                        </tr> 
                                                    ))
                                                    ):(
                                                        <tr>
                                                            <td colSpan="4" style={{textAlign:"center"}}>                                            
                                                                No hay información                                            
                                                            </td>                                        
                                                        </tr>                            
                                                    ) 
                                                                            
                                            }
                                        </tbody>
                                    </table>                                   
                                </div>
                            </div>    
                        </div> 
                    }    
                     
                </div>
            </div>
        </div>
        </>
    );
      
}