import React, { useState, useEffect } from "react";
import {Link} from 'react-router-dom'
import '../css/nav.css'

const url_consulta = "../../../servicios/menu_arcsa";
function Nav() {
    const navStyle = {
        color: "white"
    }
    
    const [items, setItems] = useState([]);
    useEffect(() => {
        let formData = new FormData();
        formData.append('arcsa', '');
        const requestOptions = {
            method: 'POST',
            body: formData
        };
        const fetchItems = async () => {    
            const data = await fetch (url_consulta,requestOptions);
            const items = await data.json();
            setItems(items.data[0]); 
        }
        fetchItems();
    }, []);    
    return(
        <>
        <nav>
             <ul className="nav-links">
                {                       
                  items.map((val, key) =>{                    
                  return( 
                    <Link key={key} style={navStyle} to={val.link}>
                        <li key={key} data-toggle="tooltip" data-placement="top" title={val.title}>
                            <img src={val.imgurl} width="36px" height="36px" alt="..."/><br/>
                            {val.title}
                        </li>
                    </Link>           
                  )
                  })
                }             
            </ul> 
        </nav>   
       </>
    )
}

export default Nav;