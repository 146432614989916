import React, { useRef, useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';

export const useMountEffect = (fun) => useEffect(fun, []);

const UseFocus = () => {
	const htmlElRef = useRef(null)
	const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

	return [ htmlElRef,  setFocus ] 
}

export default function MyForm(){

    const [itemsProceso, setItemsProceso] = useState([]); 
    const [itemsProvincia, setItemsProvincia] = useState([]);
    const [input1Ref, setInput1Focus] = UseFocus();

    useMountEffect( setInput1Focus );
    
    useEffect(() => {
        let formData = new FormData();

         //función para consultar procesos
        const fetchItemsProcesos = async () => {
            formData.append('arcsa', '');
            const requestOptions = {
            method: 'POST',
            body: formData
            };      
          const data = await fetch (urlProcesos,requestOptions);
          const itemsProceso = await data.json();
          console.log('itemsProceso',itemsProceso.data[3])
          setItemsProceso(itemsProceso.data[3]); 
        }  
        fetchItemsProcesos();

        // cargamos provincias
        const fetchItemsProvincias = async () => {
            formData.append('arcsa', '');
            const requestOptions = {
            method: 'POST',
            body: formData
            };      
          const data = await fetch (urlProvincias,requestOptions);
          const itemsProvincia = await data.json();
          setItemsProvincia(itemsProvincia.data); 
        }  
        fetchItemsProvincias();
        
    }, []);
    const [open, setOpen] = React.useState(false);
    const [info, setInfo] = React.useState(false);
    const [spiner, setSpiner] = React.useState(false);
  //  const urlValidar = "servicios/validar_info";
    const urlValidarCedula = "servicios/validar_cedula";
    const urlProvincias = "servicios/consultar_provincia";
    const urlProcesos = "servicios/aplicativos_arcsa_sin";
    const urlGeneraCodigo = "servicios/generar_codigo_arcsa_sin_solicitud_cedula";
    const urlValidamosCodigo = "servicios/validar_codigo_arcsa_sin_solicitud";
    const urlingresarSolicitud = "servicios/ingresar_correspondencia_arcsa_sin_solicitud_cedula";    

    let formData = new FormData();

   
    const [state, setState] = React.useState({
        disabled : false,
        titleMessage: "",
        solicitud_id: 0,
        linea: "",
        bodyMessage: "",
        provincia: 0,
        canton: 0,
        parroquia: 0,
        ruc: "",
        estb: "",
        cedula: "",
        select: 0,
        texto: "",
        proceso: "",
        correo: "",
        textoObse: "",
        validarKeyProceso: 0,
        validarKeyCorreo: 0,
        codigo: "",
        nombre: "",
        key : ""
    })

  
 // Gneral Focus Hook
// const UseFocus = () => {
// 	const htmlElRef = useRef(null)
// 	const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

// 	return [ htmlElRef,  setFocus ] 
// }

    // useEffect(() => {

        
    // },[]);

    const handleClose = () => {
        setOpen(false);
    };
    
    // función para validar solo números 
    function handleChange(evt) {
        const value = evt.target.value.replace(/\D/g, "");
        setState({
            ...state,
            [evt.target.name]: value
        });
    };

    function handleChangeTexto(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
    };

    
     
     
    // función para validar ruc y cédula
    // const [validarRest, setValidarRest] = useState([]);
    // const handleValidar = async () => {
    //     if(state.ruc === ""){
    //         setOpen(true);
    //         setState({texto : "" ,
    //             select : 0, 
    //             proceso : "",
    //             titleMessage: 'Advertencia',
    //             bodyMessage: "Ingresar número de Ruc",
    //             ruc : state.ruc ,
    //             estb : state.estb,
    //             cedula : state.cedula
    //         });
    //     }else{
    //         if(state.estb === 0){
    //             setOpen(true);
    //             setState({texto : "" ,
    //                 select : 0, 
    //                 proceso : "",
    //                 titleMessage: 'Advertencia',
    //                 bodyMessage: "Ingresar número de establecimiento",
    //                 ruc : state.ruc ,
    //                 estb : state.estb,
    //                 cedula : state.cedula
    //             });
    //         }else{
    //             if(state.cedula === ""){
    //                 setOpen(true);
    //                 setState({texto : "" ,
    //                     select : 0, 
    //                     proceso : "",
    //                     titleMessage: 'Advertencia',
    //                     bodyMessage: "Ingresar número de cédula",
    //                     ruc : state.ruc ,
    //                     estb : state.estb,
    //                     cedula : state.cedula
    //                 });
    //             }else{
    //                 setSpiner(true);        
    //                 formData.append('ruc', state.ruc);
    //                 formData.append('estb', state.estb);
    //                 formData.append('cedula', state.cedula);
    //                 const requestOptions = {
    //                     method: 'POST',
    //                     body: formData
    //                 };
    //                 const resp = await fetch (urlValidar, requestOptions);       
                    
    //                 const validarRest = await resp.json();
    //                 if(validarRest.error === 0){                        
    //                     setValidarRest(validarRest.data); 
    //                     setOpen(true);
    //                     setState({texto : "" ,
    //                         select : 0, 
    //                         proceso : "",
    //                         titleMessage: validarRest.titulo,
    //                         bodyMessage: validarRest.mensaje,
    //                         ruc : state.ruc ,
    //                         estb : state.estb,
    //                         cedula : state.cedula
    //                     });
    //                     window.scrollTo(0, 200);
    //                 }else{
    //                     setValidarRest(validarRest.data); 
    //                     setOpen(true);
    //                     setState({texto : "" ,
    //                         select : 0, 
    //                         proceso : "",
    //                         titleMessage: validarRest.titulo,
    //                         bodyMessage: validarRest.mensaje,
    //                         ruc : state.ruc ,
    //                         estb : state.estb,
    //                         cedula : state.cedula
    //                     });
    //                 }
    //                 setSpiner(false);
    //             }
    //         }
    //     }
        
    // }


     // función para validar ruc y cédula  Ini 03-03-2022 DAC
    
     const [validarRest, setValidarRest] = useState([]);
     const handleValidar = async () => {
        
        if (state.cedula === ""){ 
            console.log('cedula: ', state.cedula)
            setOpen(true);
             setState({texto : "" ,
                 select : 0, 
                 proceso : "",
                 titleMessage: 'Advertencia',
                 bodyMessage: "Ingresar número de cédula",
                 ruc : state.ruc ,
                 estb : state.estb,
                 cedula : state.cedula
             });
    
        }
          else{
                setSpiner(true);        
                formData.append('cedula', state.cedula);
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const resp = await fetch (urlValidarCedula, requestOptions);       
                
                const validarRest = await resp.json();
              console.log('validarRest', JSON.stringify(validarRest));
              console.log('error',validarRest.error)
                if(validarRest.error === 0){                        
                    setValidarRest(validarRest.data); 
                    setOpen(true);
                    setState({texto : "" ,
                        select : 0, 
                        proceso : "",
                        titleMessage: validarRest.titulo,
                        bodyMessage: validarRest.mensaje,
                        // ruc : state.ruc ,
                        // estb : state.estb,
                        cedula : state.cedula
                    });
                    window.scrollTo(0, 200);
                }else{
                    setValidarRest(validarRest.data); 
                    setOpen(true);
                    setState({texto : "" ,
                        select : 0, 
                        proceso : "",
                        titleMessage: validarRest.titulo,
                        bodyMessage: validarRest.mensaje,
                        // ruc : state.ruc ,
                        // estb : state.estb,
                        cedula : state.cedula
                    });
                }
                setSpiner(false);
            }
        }
    
        // if(state.ruc === ""){
        //      setOpen(true);
        //      setState({texto : "" ,
        //          select : 0, 
        //          proceso : "",
        //          titleMessage: 'Advertencia',
        //          bodyMessage: "Ingresar número de Ruc Dennys",
        //          ruc : state.ruc ,
        //          estb : state.estb,
        //          cedula : state.cedula
        //      });
        //  }else{
        //      if(state.estb === 0){
        //          setOpen(true);
        //          setState({texto : "" ,
        //              select : 0, 
        //              proceso : "",
        //              titleMessage: 'Advertencia',
        //              bodyMessage: "Ingresar número de establecimiento",
        //              ruc : state.ruc ,
        //              estb : state.estb,
        //              cedula : state.cedula
        //          });
        //      }else{
        //          if(state.cedula === ""){
        //              setOpen(true);
        //              setState({texto : "" ,
        //                  select : 0, 
        //                  proceso : "",
        //                  titleMessage: 'Advertencia',
        //                  bodyMessage: "Ingresar número de cédula",
        //                  ruc : state.ruc ,
        //                  estb : state.estb,
        //                  cedula : state.cedula
        //              });
        //          }else{
        //              setSpiner(true);        
        //              formData.append('ruc', state.ruc);
        //              formData.append('estb', state.estb);
        //              formData.append('cedula', state.cedula);
        //              const requestOptions = {
        //                  method: 'POST',
        //                  body: formData
        //              };
        //              const resp = await fetch (urlValidar, requestOptions);       
                     
        //              const validarRest = await resp.json();
        //              if(validarRest.error === 0){                        
        //                  setValidarRest(validarRest.data); 
        //                  setOpen(true);
        //                  setState({texto : "" ,
        //                      select : 0, 
        //                      proceso : "",
        //                      titleMessage: validarRest.titulo,
        //                      bodyMessage: validarRest.mensaje,
        //                      ruc : state.ruc ,
        //                      estb : state.estb,
        //                      cedula : state.cedula
        //                  });
        //                  window.scrollTo(0, 200);
        //              }else{
        //                  setValidarRest(validarRest.data); 
        //                  setOpen(true);
        //                  setState({texto : "" ,
        //                      select : 0, 
        //                      proceso : "",
        //                      titleMessage: validarRest.titulo,
        //                      bodyMessage: validarRest.mensaje,
        //                      ruc : state.ruc ,
        //                      estb : state.estb,
        //                      cedula : state.cedula
        //                  });
        //              }
        //              setSpiner(false);
        //          }
        //      }
        //  }
         
    //  }


     // función para validar ruc y cédula  Fin 03-03-2022 DAC

    //funcion al cambiar de provincia
    const urlConsultaCanton = "servicios/consultar_canton";
    const [itemsCanton, setItemsCanton] = useState([]);   
    const handledChangeProvincia = async (evt) => {
        setSpiner(true); 
        formData.append('provincia', evt.target.value);        
        const requestOptions = {
            method: 'POST',
            body: formData
        };      
        const data = await fetch (urlConsultaCanton,requestOptions);
        const itemsCanton = await data.json();
        if(itemsCanton.error === 0){  
            setItemsCanton(itemsCanton.data);    
            setState({
                select : 0, 
                provincia : evt.target.value,
                ruc : state.ruc ,
                estb : state.estb,
                cedula : state.cedula});
        }else{
            setState({                select : 0, 
                
                proceso : evt.target.value,
                ruc : state.ruc ,
                titleMessage: "Mensaje",
                bodyMessage: itemsLineasProceso.mensaje,
                estb : state.estb,
                cedula : state.cedula});
                setOpen(true);
        }
        setSpiner(false); 
    }

    //funcion al cambiar de canton
    const urlConsultaParroquia = "servicios/consultar_parroquia";
    const [itemsParroquia, setItemsParroquia] = useState([]);   
    const handledChangeCanton = async (evt) => {
        setSpiner(true); 
        formData.append('canton', evt.target.value);        
        const requestOptions = {
            method: 'POST',
            body: formData
        };      
        const data = await fetch (urlConsultaParroquia,requestOptions);
        const itemsParroquia = await data.json();
        if(itemsParroquia.error === 0){  
            setItemsParroquia(itemsParroquia.data);    
            setState({
                select : 0, 
                canton : evt.target.value,
                provincia : state.provincia,
                ruc : state.ruc ,
                estb : state.estb,
                cedula : state.cedula});
        }else{
            setState({                select : 0, 
                
                proceso : evt.target.value,
                ruc : state.ruc ,
                titleMessage: "Mensaje",
                bodyMessage: itemsLineasProceso.mensaje,
                estb : state.estb,
                cedula : state.cedula});
                setOpen(true);
        }
        setSpiner(false); 
    }

    const handledChangeParroquia = async (evt) => {
        setState({
            select : 0, 
            parroquia : evt.target.value,
            canton : state.canton,
            provincia : state.provincia,
            ruc : state.ruc ,
            estb : state.estb,
            cedula : state.cedula});
    }


    //función mostrar id validar proceso 
    const urlLienaProceso = "servicios/consultar_procesos_linea_sin_solicitud";
    const [itemsLineasProceso, setItemsLineasProceso] = useState([]);   
    const handledChangeProceso = async (evt) => {
        setSpiner(true); 
        formData.append('linea', evt.target.value);
        formData.append('ruc', state.ruc);
        formData.append('est', state.estb);
        formData.append('texto', evt.nativeEvent.target[evt.target.selectedIndex].text);
        
        const requestOptions = {
            method: 'POST',
            body: formData
        };      
        const data = await fetch (urlLienaProceso,requestOptions);
        const itemsLineasProceso = await data.json();
        const textoSelect = "Código de " + evt.nativeEvent.target[evt.target.selectedIndex].text;
        if(itemsLineasProceso.error === 0 && evt.target.value !== "0" ){  
            setItemsLineasProceso(itemsLineasProceso.data[0]);     
            
            setState({texto : textoSelect ,
                select : 0, 
                linea : textoSelect,
                proceso : evt.target.value,
                parroquia : state.parroquia,
                canton : state.canton,
                provincia : state.provincia,
                ruc : state.ruc ,
                estb : state.estb,
                //Ini Agregado 24-02-2022 DAC
                validarKeyProceso : 1,
                //Fin Agregado 24-02-2022 DAC
                cedula : state.cedula});
             
        }else{
            setState({texto : textoSelect ,
                select : 0, 
                linea : textoSelect,
                proceso : evt.target.value,
                ruc : state.ruc ,
                titleMessage: "Mensaje",
                bodyMessage: "Seleccione Categoría",//itemsLineasProceso.mensaje,
                estb : state.estb,
                cedula : state.cedula});
                setOpen(true);
        }
         setSpiner(false); 
    }

     //función mostrar id validar proceso por cedula DAC
     const urlLienaProcesoCedula = "servicios/consultar_procesos_linea_sin_solicitud_x_cedula";
     const [itemsLineasProceso1, setitemsLineasProceso1] = useState([]);   

     const handledChangeProcesoCedula = async (evt) => {
        console.log('linea', evt.target.value);
         setSpiner(true); 
         formData.append('linea', evt.target.value);
         formData.append('cedula', state.cedula);
         formData.append('texto', evt.nativeEvent.target[evt.target.selectedIndex].text);
                 
         const requestOptions = {
             method: 'POST',
             body: formData
         };      
         const data = await fetch (urlLienaProcesoCedula,requestOptions);
         const itemsLineasProceso1 = await data.json();
         console.log('itemsLineasProceso1', JSON.stringify(itemsLineasProceso1));

         const textoSelect = "Código de " + evt.nativeEvent.target[evt.target.selectedIndex].text;
         if(itemsLineasProceso1.error === 0 && evt.target.value !== "0" ){  
             setitemsLineasProceso1(itemsLineasProceso1.data[0]);     
             
             setState({texto : textoSelect ,
                 select : 0, 
                 linea : textoSelect,
                 proceso : evt.target.value,
                 parroquia : state.parroquia,
                 canton : state.canton,
                 provincia : state.provincia,
                //  ruc : state.ruc ,
                //  estb : state.estb,
                 //Ini Agregado 24-02-2022 DAC
                 validarKeyProceso : 1,
                 //Fin Agregado 24-02-2022 DAC
                 cedula : state.cedula});
              
         }else{
             setState({texto : textoSelect ,
                 select : 0, 
                 linea : textoSelect,
                 proceso : evt.target.value,
                 ruc : state.ruc ,
                 titleMessage: "Mensaje",
                 bodyMessage: "Seleccione Categoría",//itemsLineasProceso1.mensaje,
                 estb : state.estb,
                 cedula : state.cedula});
                 setOpen(true);
         }
          setSpiner(false); 
     }


    // const handledChangeProcesoLinea = async (evt) => {
    //     const textoSelect = "Su selección: " + evt.nativeEvent.target[evt.target.selectedIndex].text;
    //     if(evt.target.value === "0" | evt.target.value === "99"){
    //         setState({texto : textoSelect ,
    //              select : 0, 
    //              linea : state.linea,
    //              proceso : evt.target.value,
    //              ruc : state.ruc ,
    //              estb : state.estb,
    //              titleMessage: "Mensaje",
    //             bodyMessage: "Seleccione un proceso",
    //              cedula : state.cedula});
    //             setOpen(true);
            
    //         }else{
    //              setState({texto : textoSelect ,
    //              select : 1, 
    //              linea : state.linea,
    //              proceso : evt.target.value,
    //              ruc : state.ruc ,
    //              estb : state.estb,
    //              parroquia : state.parroquia,
    //             canton : state.canton,
    //             provincia : state.provincia,
    //             validarKeyProceso : 1,
    //              cedula : state.cedula});
    //         }
       
    // }
    
    //validamos el código y correo
    const handleGenerarCodigo = async () => {
       // const textoSelect = state.proceso;
       // const key = keyValidar.current.value;
        const correo = state.correo;
        //const ruc = state.ruc;
        //const estb = state.estb;
        //const cedula = state.cedula;
        formData.append('proceso', state.proceso);
        formData.append('provincia', state.provincia);
        formData.append('canton', state.canton);
        formData.append('parroquia', state.parroquia);
        formData.append('ruc', state.ruc);
        formData.append('estb', state.estb);
        formData.append('cedula', state.cedula);
        formData.append('correo', state.correo);
        const requestOptions = {
            method: 'POST',
            body: formData
        };
        setSpiner(true);
        // Validamos si el formato del Email es correcto 
        if(typeof correo === "undefined" | correo === ""){
            setState({
                texto : state.texto,
                select : 1,
                key : state.key,
                proceso : state.proceso,
                titleMessage: "Validación de Información",
                bodyMessage: "Ingresar un correo electrónico",
                ruc : state.ruc ,
                parroquia : state.parroquia,
                canton : state.canton,
                provincia : state.provincia,
                correo : state.correo,
                obs : "",
                estb : state.estb,
                cedula : state.cedula,
                validarKeyProceso : 1
            });
            setOpen(true);
        }else{
            const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;       
                if (!emailRegex.test(correo)) {
                    setState({
                        texto : state.texto,
                        select : 1,
                        key : state.key,
                        proceso : state.proceso,
                        titleMessage: "Validación de Información",
                        bodyMessage: "Ingresar un correo válido",
                        ruc : state.ruc ,
                        correo : state.correo,
                        obs : "",
                        parroquia : state.parroquia,
                        canton : state.canton,
                        provincia : state.provincia,
                        estb : state.estb,
                        cedula : state.cedula,
                        validarKeyProceso : 1
                    });
                    setOpen(true);
                }else{
                    setSpiner(true);
                    const resp = await fetch (urlGeneraCodigo,requestOptions);                    
                    const validarRest = await resp.json();
                    if(validarRest.error === 0){
                        setState({
                            texto : state.texto,
                            select : 1,
                            solicitud_id : validarRest.id,
                            codigo : state.codigo,
                            key : state.key,
                            correo : state.correo,
                            proceso : state.proceso,
                            parroquia : state.parroquia,
                            canton : state.canton,
                            provincia : state.provincia,
                            titleMessage: validarRest.titulo,
                            bodyMessage: validarRest.mensaje,
                            // ruc : state.ruc ,
                            // estb : state.estb, 
                            cedula : state.cedula,
                            validarKeyProceso : 1,
                            validarKeyCorreo : 0
                        });
                        setOpen(true);        
                    }else{
                        setState({
                            texto : state.texto,
                            select : 1,
                            solicitud_id : 0,
                            codigo : state.codigo,
                            key : state.key,
                            correo : state.correo,
                            proceso : state.proceso,
                            titleMessage: validarRest.titulo,
                            bodyMessage: validarRest.mensaje,
                            parroquia : state.parroquia,
                            canton : state.canton,
                            provincia : state.provincia,
                            ruc : state.ruc ,
                            estb : state.estb, 
                            cedula : state.cedula,
                            validarKeyProceso : 1,
                            validarKeyCorreo : 0
                        });
                        setOpen(true); 
                    }             
                    
                }
        }
        setSpiner(false);       
    }

    //validamos el código y correo por Cedula DAC
    const handleGenerarCodigoCedula = async () => {

        const find = validarRest.find(item => item.NOMBRE);
        const nombre = find.NOMBRE;

        // console.log('nombre:' , nombre);
   
         const correo = state.correo; 
        // console.log('state.proceso',state.proceso);
        // console.log('state.provincia',state.provincia);
        // console.log('state.canton',state.canton);
        // console.log('state.parroquia',state.parroquia);
        // console.log('state.cedula',state.cedula);
        // console.log('state.correo',state.correo);

         formData.append('proceso', state.proceso);
         formData.append('provincia', state.provincia);
         formData.append('canton', state.canton);
         formData.append('parroquia', state.parroquia);
         formData.append('cedula', state.cedula);
         formData.append('nombre', nombre);
         formData.append('correo', state.correo);
         const requestOptions = {
             method: 'POST',
             body: formData
         };
         setSpiner(true);
         // Validamos si el formato del Email es correcto 
         if(typeof correo === "undefined" | correo === ""){
             setState({
                 texto : state.texto,
                 select : 1,
                 key : state.key,
                 proceso : state.proceso,
                 titleMessage: "Validación de Información",
                 bodyMessage: "Ingresar un correo electrónico",
                 ruc : state.ruc ,
                 parroquia : state.parroquia,
                 canton : state.canton,
                 provincia : state.provincia,
                 correo : state.correo,
                 obs : "",
                 estb : state.estb,
                 cedula : state.cedula,
                 validarKeyProceso : 1
             });
             setOpen(true);
         }else{
             const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;       
                 if (!emailRegex.test(correo)) {
                     setState({
                         texto : state.texto,
                         select : 1,
                         key : state.key,
                         proceso : state.proceso,
                         titleMessage: "Validación de Información",
                         bodyMessage: "Ingresar un correo válido",
                         ruc : state.ruc ,
                         correo : state.correo,
                         obs : "",
                         parroquia : state.parroquia,
                         canton : state.canton,
                         provincia : state.provincia,
                         estb : state.estb,
                         cedula : state.cedula,
                         validarKeyProceso : 1
                     });
                     setOpen(true);
                 }else{
                     setSpiner(true);
                     const resp = await fetch (urlGeneraCodigo,requestOptions);                    
                     const validarRest = await resp.json();
                     console.log('valida: ', validarRest)
                     if(validarRest.error === 0){
                         setState({
                             texto : state.texto,
                             select : 1,
                             solicitud_id : validarRest.id,
                             codigo : state.codigo,
                             key : state.key,
                             correo : state.correo,
                             proceso : state.proceso,
                             parroquia : state.parroquia,
                             canton : state.canton,
                             provincia : state.provincia,
                             titleMessage: validarRest.titulo,
                             bodyMessage: validarRest.mensaje,
                             nombre: nombre,
                             cedula : state.cedula,
                             validarKeyProceso : 1,
                             validarKeyCorreo : 0
                         });
                         setOpen(true);        
                     }else{
                         setState({
                             texto : state.texto,
                             select : 1,
                             solicitud_id : 0,
                             codigo : state.codigo,
                             key : state.key,
                             correo : state.correo,
                             proceso : state.proceso,
                             titleMessage: validarRest.titulo,
                             bodyMessage: validarRest.mensaje,
                             parroquia : state.parroquia,
                             canton : state.canton,
                             provincia : state.provincia,
                             cedula : state.cedula,
                             validarKeyProceso : 1,
                             validarKeyCorreo : 0
                         });
                         setOpen(true); 
                     }             
                     
                 }
         }
         setSpiner(false);       
     }

    //validamos correo, código e ingresamos la solicitud de correspondencia con estado 0 y validación código 0
    const handleValidarCodigo = async () => {
        const solicitud_id = state.solicitud_id;
        const codigo = state.codigo;
        // Validamos si el formato del Email es correcto 
        if(typeof codigo === "undefined" | codigo === ""){
            setState({
                texto : state.texto,
                select : 1,
                key : state.key,
                proceso : state.proceso,
                titleMessage: "Validación de Información",
                bodyMessage: "Recuerde escribir el código enviado.",
                ruc : state.ruc ,
                correo : state.correo,
                obs : state.textoObse,
                estb : state.estb,
                cedula : state.cedula,
                validarKeyProceso : 1
            });
            setOpen(true);
        }else{
            setSpiner(true);
            formData.append('solicitud_id', solicitud_id);
            formData.append('codigo', codigo);
            const requestOptions = {
                method: 'POST',
                body: formData
            };
            const resp = await fetch (urlValidamosCodigo,requestOptions);
            
            const validarRest = await resp.json();
            if(validarRest.error === 1){
                console.log('Ingresó:')
                setState({
                    texto : state.texto,
                    select : 1,
                    solicitud_id : state.solicitud_id,
                    codigo : state.codigo,
                    key : state.key,
                    proceso : state.proceso,
                    titleMessage: validarRest.titulo,
                    bodyMessage: validarRest.mensaje,
                    ruc : state.ruc ,
                    estb : state.estb, 
                    cedula : state.cedula,
                    validarKeyProceso : 1,
                    validarKeyCorreo : 0
                });
                console.log('paso aki')
                //setOpen(true);
                //this.state.textoObse.focus();
                //state.textoObse.autoFocus()
                //window.scrollTo(0, 400);         
            }else{
                setState({
                    texto : state.texto,
                    select : 1,
                    solicitud_id : state.solicitud_id,
                    codigo : state.codigo,
                    key : state.key,
                    proceso : state.proceso,
                    titleMessage: validarRest.titulo,
                    bodyMessage: validarRest.mensaje,
                    ruc : state.ruc ,
                    estb : state.estb, 
                    cedula : state.cedula,
                    validarKeyProceso : 1,
                    validarKeyCorreo : 1
                });
                console.log('paso aki scroll');
               // setInput1Focus();
                //setState(state.textoObse.autoFocus());
                //this.setInput1Focus();
                //state.textoObse.autoFocus();
            
            }  
        }  
        setSpiner(false);   
        setInput1Focus();   
    }

    const handleModalInfo = async () => {
        setInfo(true);
    }

    const handleCloseInfo = async () => {
        setInfo(false);
    }

    //enviamos correspondencia
    const handleEnviarCorrespondencia = async () => {
        const id = state.solicitud_id;
        const obs = escape(state.textoObse);
        console.log('id',id);
        console.log('obs',obs);
        formData.append('id', id);
        formData.append('obs', obs);
        const requestOptions = {
            method: 'POST',
            body: formData
        };
        // Validamos si el formato del Email es correcto 
        if(typeof obs === "undefined" | obs === ""){
            setState({
                titleMessage: "Validación de Información",
                bodyMessage: "Ingresar una observación",
                select : 1,
                solicitud_id : state.solicitud_id,
                codigo : state.codigo,
                key : state.key,
                proceso : state.proceso,                
                ruc : state.ruc ,
                estb : state.estb, 
                cedula : state.cedula,
                validarKeyProceso : 1,
                validarKeyCorreo : 1
            });
            setOpen(true);
        }else{
            setSpiner(true);
            const resp = await fetch (urlingresarSolicitud,requestOptions);            
            const validarRest = await resp.json();
            if(validarRest.error === 0){
                setOpen(true);
                setState({
                    titleMessage: validarRest.titulo,
                    bodyMessage: validarRest.mensaje
                });   
                setSpiner(false);
                setTimeout(function() {                            
                    window.location.reload();
                }, 5000);         
            }else{

            } 
        }       
    }    


      
    return (
        <>
        {/*Modal info*/}

        <Dialog open={info}  onClose={handleClose}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Términos de Correspondencia</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <>
              
                <strong><i class="fas fa-map-marker-alt mr-1"></i>Categoría Tramite:</strong>

                <p class="text-muted">corresponde al grupo de procesos o tramites que nuestros usuarios pueden gestionar cono ARCSA  </p>

                <hr/>

                <strong><i class="fas fa-pencil-alt mr-1"></i>Proceso:</strong>

                <p class="text-muted">corresponde al trámite o consulta especifica referente a un trámite cual ha sido catalogada para poder responder canalizar su consulta con el personal idóneo de ARCSA.  </p>


                <hr/>

                <strong><i class="far fa-file-alt mr-1"></i>Correo electrónico: </strong>

                <p class="text-muted">
                corresponde al buzón declarado por el solicitante en el cual recibirá códigos de validaciones para el ingreso de sus interacciones y las respuestas por parte de ARCSA
                </p>

                <hr/>

                <strong><i class="far fa-file-alt mr-1"></i>Código de validación: </strong>

                <p class="text-muted">
                corresponde a un pin de validación previa a la correspondencia que se remite al correo electrónico declarado para la interacción del solicitante y la confirmación de buzón de destino para las respuestas que emitidas por ARCSA.                </p>


                <hr/>

                <strong><i class="far fa-file-alt mr-1"></i>Detalle de Información: </strong>

                <p class="text-muted">
                Corresponde a la consulta especifica en donde el solicitante proporciona toda la información relacionada con la Referencia ingresada, considerando que debe ser clara y detallada para el correcto entendimiento y atención de la misma.                </p>
               
               
                <hr/>

                <p class="text-muted">
                <b>Importante:</b> las correspondencias ingresadas por el ciudadano, con para interacciones de un trámite en curso y catalogado por ARCSA, en donde deben seleccionar la categoría y proceso debido de la misma manera que la referencia ingresada debe existir. Y el detalle de la correspondencia debe indicar clara y objetivamente el problema y/o pregunta a realizar. En caso de que estos elementos no tengan la pertinencia la correspondencia no podrá ser válida.
                </p>
               
                </>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseInfo} color="primary" autoFocus>
                Cerrar
            </Button>
            </DialogActions>
        </Dialog>
         
          {/*Modal mensaje*/}
        <Dialog open={open}  onClose={handleClose}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{state.titleMessage}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {state.bodyMessage}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
                Cerrar
            </Button>
            </DialogActions>
        </Dialog>

        {/*Modal Cargadno*/}
        <Dialog open={spiner}   aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title text-center">Consultando información</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description" className="text-center">
                <CircularProgress />
            </DialogContentText>
            </DialogContent>
        </Dialog>
        <input type="hidden" value={state.solicitud_id} id="solicitud_id"  onChange={handleChangeTexto}/>
        <div className="row">
            <div className="col-lg-12 form-group">          
                <fieldset>                    
                    <div className="alert alert-success alert-dismissable">
                        <div className="row text-justify">
                            <div className="col-md-12">
                                <b>Estimado Usuario:</b> la correspondencia es un servicio de interacción objetiva entre un solicitante y personal técnico de la agencia para facilitar el análisis de lo reportado acerca de tema específico con ARCSA 
                                &nbsp;&nbsp;&nbsp;<a href="#!" onClick={handleModalInfo}  className="btn btn-info">Ayuda <InfoIcon/></a>                     
                            </div>
                        </div>
                    </div>
                </fieldset> 
            </div>
            <div className="col-lg-12 form-group">          
                <fieldset>                    
                    <div className="alert alert-secondary alert-dismissable">
                        <div className="row text-justify">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12" >
                                        <b>Ingrese información del remitente</b> 
                                    </div>
                                    {/* <div className="col-md-4 form-group">
                                        <label>Ruc:</label>
                                        <input  value={state.ruc} onChange={handleChange} maxLength="13" id="ruc" name="ruc" type="text" placeholder="Ruc" className="form-control"/>
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label>Establecimiento:</label>
                                        <input value={state.estb}  onChange={handleChange} maxLength="3" id="estb" name="estb" type="text" placeholder="Establecimiento" className="form-control"/>
                                    </div> */}
                                    <div className="col-md-4 form-group">
                                        <label>Cédula:</label>
                                        <input value={state.cedula} onChange={handleChange}  maxLength="10" id="cedula" name="cedula" type="text" placeholder="Cédula" className="form-control"/>
                                    </div>                                    
                                </div>                                
                            </div>
                            <div className="form-group col-md-12">
                                <div className="col-md-12 text-center">
                                    <button onClick={handleValidar}  type="submit" className="btn btn-primary btn-sm">Verificar información</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">                       
                    </div> 
                </fieldset>                    
                
            </div>      
            {
                validarRest.length > 0 &&                      
                    <div className="col-lg-12 form-group">          
                        <fieldset>                                                                                         
                            <table className="table table-bordered table-responsive-xl">
                                <thead>
                                    <tr>
                                        <th className="table-primary" colSpan="4"><b style={{fontWeight:"bold"}}>Información del solicitante de correspondencia</b></th>
                                    </tr>
                                    <tr>
                                        {/* <th style={{textAlign:"center"}}>Ruc</th>
                                        <th style={{textAlign:"center"}}>Razón Social</th> */}
                                        <th style={{textAlign:"center"}}>Cédula</th>
                                        <th style={{textAlign:"center"}}>Nombre</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                   
                                   validarRest.map((val, key) =>( 
                                    <tr id={key}>
                                        {/* <td style={{textAlign:"center"}}>{val.ruc}</td>
                                        <td style={{textAlign:"center"}}>{val.razon}</td> */}
                                        <td style={{textAlign:"center"}}>{val.cedula}</td>
                                        <td style={{textAlign:"center"}}>{val.NOMBRE}</td>
                                    </tr>
                                ))
                                    }                                                            
                                </tbody>                                            
                            </table> 
                        </fieldset>   
                    </div>                               
            }     
            {
                validarRest.length > 0 &&
                <div className="col-lg-12 form-group">          
                    <fieldset className="form-group"> 

                    <div className="form-group">
                            <b style={{fontWeight:"bold"}}>Seleccione Ubicación:</b>
                        </div>
                        <fieldset> 
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-4 form-group">
                                        <label>Provincia:</label>                                                                        
                                        <select id="provincia" onChange={handledChangeProvincia} className="form-control form-group">
                                            <option value="0">Seleccionar Provincia</option>
                                            {
                                                itemsProvincia.length > 0 ? (
                                                    itemsProvincia.map(val => ( 
                                                        <option value={val.id}>{val.nombre}</option>
                                                    ))
                                                ):(
                                                    <option value="99">No existen opciones</option>                                            
                                                )                                
                                            }    
                                        </select>
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label>Cantón:</label>                                                                        
                                        <select id="canton" onChange={handledChangeCanton} className="form-control form-group">
                                            <option value="0">Seleccionar Cantón</option>
                                            {
                                                itemsCanton.length > 0 ? (
                                                    itemsCanton.map(val => ( 
                                                        <option value={val.id}>{val.nombre}</option>
                                                    ))
                                                ):(
                                                    <option value="99">No existen opciones</option>                                            
                                                )                                
                                            }    
                                        </select>
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label>Parroquia:</label>                                                                        
                                        <select id="parroquia" onChange={handledChangeParroquia}  className="form-control form-group">
                                            <option value="0">Seleccionar Parroquia</option>
                                            {
                                                itemsParroquia.length > 0 ? (
                                                    itemsParroquia.map(val => ( 
                                                        <option value={val.id}>{val.nombre}</option>
                                                    ))
                                                ):(
                                                    <option value="99">No existen opciones</option>                                            
                                                )                                
                                            }    
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </fieldset> 

                        <div className="form-group">
                            <b style={{fontWeight:"bold"}}>Seleccione Tipo de correspondencia:</b>
                        </div>
                        <fieldset> 
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <label>Categoría de trámite ARCSA:</label>                                                                        
                                        <select onChange={handledChangeProcesoCedula} className="form-control form-group">
                                            <option value="0">Seleccionar categoría</option>
                                            {
                                                itemsProceso.length > 0 ? (
                                                    itemsProceso.map(val => ( 
                                                        <option value={val.id}>{val.title}</option>
                                                    ))
                                                ):(
                                                    <option value="99">No existen opciones</option>                                            
                                                )                                
                                            }    
                                        </select>
                                    </div>
                                    {/* <div className="col-md-6 form-group">
                                        <label>Seleccionar trámite específico:</label>                                                                        
                                        <select onChange={handledChangeProcesoLinea} className="form-control form-group">
                                            <option value="0">Seleccionar opción</option>
                                            {
                                                itemsLineasProceso.length > 0 ? (
                                                    itemsLineasProceso.map(val => ( 
                                                        <option value={val.id}>{val.descripcion}</option>
                                                    ))
                                                ):(
                                                    <option value="99">No existen opciones</option>                                            
                                                )                                
                                            }    
                                        </select>
                                    </div> */}
                                </div>
                            </div>
                        </fieldset> 
                        {
                            state.validarKeyProceso > 0 &&
                                state.validarKeyProceso === 1 ? (
                                    <>
                                    <div className="form-group">
                                        <b style={{fontWeight:"bold"}}> Para validar su correo electrónico enviaremos un código de confirmación, recuerde ingresar un correo electrónico al cual tenga acceso.</b>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-lg-4 form-group">                                             
                                            <label><b>Ingresar correo electrónico del solicitante </b></label>                               
                                            <input onChange={handleChangeTexto} value={state.correo}  maxLength="100" id="correo" name="correo" className="form-control" />
                                        </div>
                                        <div className="form-group col-md-2">                                           
                                            <label>Enviar código</label>
                                            <br/>
                                            <button type="submit" onClick={handleGenerarCodigoCedula} className="btn btn-primary btn-sm"><MailOutlineIcon/></button>
                                        </div> 
                                        <div className="col-lg-4 form-group"> 
                                            <label><b>Ingresar el código enviado a su correo </b> </label>                               
                                            <input onChange={handleChangeTexto} value={state.codigo}  maxLength="100" id="codigo" name="codigo" className="form-control" />
                                        </div>
                                        <div className="form-group col-md-2">                                           
                                            <label>Validar código</label>
                                            <br/>
                                            <button type="submit" onClick={handleValidarCodigo } className="btn btn-primary btn-sm"><CheckCircleOutlineIcon/></button>
                                        </div> 
                                    </div>
                                   
                                    </>
                                ):(
                                    state.validarKeyProceso === 2 &&
                                    <>
                                    <div className="form-group col-md-12">
                                        <div className="col-md-12 text-center">
                                            <label>El código ingresado no es el enviado a su correo electrónico {state.correo} </label>
                                        </div>
                                    </div> 
                                    </>
                                )                       
                        }
                        {
                            state.validarKeyCorreo > 0 &&
                                state.validarKeyCorreo === 1 ? (
                                     <>
                                    <div className="col-lg-12 form-group"> 
                                        <div className="row">  
                                            <label><b>Detalle la información en relación con la referencia ingresada</b></label>                                 
                                            <textarea  ref={input1Ref} rows="5" cols="50" onChange={handleChangeTexto} className="form-control" value={state.textoObse}  maxLength="5000" id="textoObse" name="textoObse"></textarea>
                                          
                                        </div>  
                                    </div>  
                                    <div className="form-group col-md-12">
                                        <div className="col-md-12 text-center">
                                            <button type="submit" onClick={handleEnviarCorrespondencia}  className="btn btn-success btn-sm">Enviar Solicitud</button>
                                        </div>
                                    </div> 
                                    </>
                                ):(
                                    state.validarKeyCorreo === 2 &&
                                    <>
                                    <div className="form-group col-md-12">
                                        <div className="col-md-12 text-center">
                                            <label>No pudimos validar tu código, vrificar si escribió bien su correo electrónico o revisar la bandeja de Spam</label>
                                        </div>
                                    </div> 
                                    </>
                                ) 
                        }
                    </fieldset> 
                </div>                                    
            } 
             
        </div>
        </>
    );
      
}