
import GridAplicativos from '../contenido/gridaplicativos';

function Main(){
    return(
      <>    
             
        <main id="main"> 
          <GridAplicativos/>  
        </main>
      </>

    )
}

export default Main;