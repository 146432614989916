// Import types
import {
  INPUT_SUCCESS,
  INPUT_FAIL,
  SESSION_SUCCESS,
  SESSION_FAIL,
  MESSAGE_SUCCESS,
  SESSION_SELECT,
  //MESSAGE_FAIL,
} from "./types";


//  Function that handles  users message
export const userMessage = (message) => async (dispatch) => {
  try {
    dispatch({ type: INPUT_SUCCESS, payload: message });    
  } catch (err) {
    dispatch({ type: INPUT_FAIL });
  }
};

let formData = new FormData();
  const url_consulta_inicial = "/servicios/bot_ini_arcsa";
  const mensajeInicial = async (url, data) =>{  
  formData.append('arcsa', '');
  const requestOptions = {
      method: 'POST',
      body: formData
  };
  const resp = await fetch (url, requestOptions);
  const json = await resp.json();
  return json;
}

export const createSession = () => async (dispatch) => {
  try {
    const data = {
      "mensaje" : ''
    }
    const respuestaJson = await mensajeInicial(url_consulta_inicial, data);   
    dispatch({
      type: SESSION_SUCCESS,
      //payload: 'Recuerda que también te puedo asistir con información sobre: Requisitos para realizar trámites, conocer ubicaciones, recuperación de claves ¡y mucho más!',
      payload: respuestaJson.data, 
    });
  } catch (err) {
    dispatch({ type: SESSION_FAIL });
  }
};

const url_consulta = "servicios/bot_arcsa";
const enviarMensaje = async (url, data) =>{
  formData.append('mensaje', data["mensaje"]);
  formData.append('id', data["id"]);
  formData.append('op', data["op"]);
  formData.append('cedula', data["cedula"]);
  formData.append('ruc', data["ruc"]);
  formData.append('opBusqueda', data["opBusqueda"]);
  const requestOptions = {
      method: 'POST',
      body: formData
  };
  const resp = await fetch (url,requestOptions);
  const json = await resp.json();
  return json;
}


//  Sends the message to the bot - API CALL
export const sendMessage = (message, id, op, opBusqueda) => async (dispatch) => {
  try {     
    
    if(message === ""){
      op = 0;
      message = 'sin texto';
    } 
    const data = {
      "mensaje" : escape(message.trim()),
      "id" : id,
      "op" : op,
      'cedula':0,
      'ruc':0,
      'opBusqueda': opBusqueda 
    }     
    //const cedula = 0;
    const regex = /^[0-9]*$/;
    const solonumeros = regex.test(message);    
    if(solonumeros === true & (message.length === 10 | message.length === 13) ){
      //ruc y cédula
      if(message.length === 10){
        const dataCedula = {
          "mensaje" : escape(message.trim()),
          "id" : id,
          "op" : op,
          'cedula':1,
          'ruc':0,
          'opBusqueda': opBusqueda 
        }
        const respuestaJson = await enviarMensaje(url_consulta, dataCedula);
        dispatch({
          type: MESSAGE_SUCCESS,
          payload: respuestaJson.data,
        });
      }
      if(message.length === 13){
        const dataRuc = {
          "mensaje" : escape(message.trim()),
          "id" : id,
          "op" : op,
          'cedula':0,
          'ruc':1,
          'opBusqueda': opBusqueda 
        }
        const respuestaJson = await enviarMensaje(url_consulta, dataRuc);
        dispatch({
          type: MESSAGE_SUCCESS,
          payload: respuestaJson.data,
        });
      }
    }else{     //solicitudes vue      
      if(op === 0){
        const respuestaJson = await enviarMensaje(url_consulta, data);
        dispatch({
          type: MESSAGE_SUCCESS,
          payload: respuestaJson.data,
        });
      }else{
        const respuestaJson = await enviarMensaje(url_consulta, data);
        dispatch({
          type: SESSION_SELECT,
          payload: respuestaJson.data,
        });
      }  
    }     
  } catch (err) {
      dispatch({
        type: MESSAGE_SUCCESS,
        payload: "No entendimos tu pregunta",
      });
  }
};


