//  Import dependencies
import React, { useEffect } from "react";
import "../css/chat.css";
// Import redux components
import { Provider } from "react-redux";
import store from "../store";

// Import chat component
import Chat from "../components/chat/Chat";

// Import action
import { createSession } from "../actions/watson";

// Import axios
import axios from "axios";

if (localStorage.session) {
  delete axios.defaults.headers.common["session_id"];
  axios.defaults.headers.common["session_id"] = localStorage.session;
} else {
  delete axios.defaults.headers.common["session_id"];
}

// Connect application to redux
const ChatBot = () => {
  useEffect(() => {
    // Check if there session
    if (!localStorage.session) {
      // Create
      store.dispatch(createSession());
    }
  });
  return (
    <>
    <img style={{cursor:"pointer", zIndex : '1'}} src="../img/chatbot_body.gif" 
      id="float-id" data-toggle="modal" data-target="#myChatBotModal"
       className="img-chat" alt="Cinque Terre" width="70" height="105"
    /> 
    <div className="container demo">      
      <div className="modal right chat fade" style={{backdrop:'false'}} id="myChatBotModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
        <div className="modal-dialog modal-dialog-chat" role="document">
        <Provider store={store}>
          <div className="container-chat">
            <Chat />
          </div>
        </Provider> 
        </div>
      </div>      
    </div>     
   </>
  );
};

export default ChatBot;
