import React, { useState, useEffect } from "react";
import {Row, Col } from 'reactstrap';
import '../css/section.css'

const url_consulta = "/servicios/aplicativos_arcsa";

function GridAplicativos(){
  useEffect(() => {
    fetchItems();
  }, []);
  const [items, setItems] = useState([]);
  const [itemsSimuladores, setItemsSimuladores] = useState([]);
  const [itemsConsultores, setItemsConsultores] = useState([]);
   //Ini cambios 14082023
  const [itemsPermisos, setItemsPermisos] = useState([]);
  //Fin cambios 14082023
  const fetchItems = async () => {
    let formData = new FormData();
    formData.append('arcsa', '');
    const requestOptions = {
      method: 'POST',
      body: formData
    };
    const data = await fetch(url_consulta, requestOptions);
    const items = await data.json();
    setItems(items.data[0]); 
    setItemsSimuladores(items.data[1]); 
    setItemsConsultores(items.data[2]);
    //Ini cambios 14082023
    setItemsPermisos(items.data[4]);
    //Fin cambios 14082023
  }
  return(  
    <>
     <div className="modal right fade" id="myModalPermisos" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header alert-primary"  style={{textAlign: "center"}}>  
              <b>Permisos</b>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
          <div className="modal-body">                   
            <Row>                  
              {                       
                itemsPermisos.map((val, key) =>{                    
                return( 
                  <React.Fragment key={val.id}>              
                  <Col sm={6}>                    
                    <div key={val.id}  className="hover01 column">                                       
                      <a  href={val.link} title={val.title} target="_Blank" rel="noreferrer" className="thumbnail iconCuadro color-link" >
                          <figure style={{textAlign: "center"}}><img src={val.imgurl} width="90px" height="77px" alt="..."/></figure>
                         <p style={{textAlign: "center", fontSize : "12px" }}>{val.title}</p> 
                      </a>
                                    
                    </div> 
                  </Col>   
                  </React.Fragment>              
                )
                })
              }
            </Row>
          </div>
        </div>
      </div>
    </div> 
    <div className="modal right fade" id="myModalSimuladores" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header alert-primary"  style={{textAlign: "center"}}>  
              <b>Simuladores para el Riesgo Sanitario de tu Producto</b>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
          <div className="modal-body">                   
            <Row>                  
              {                       
                itemsSimuladores.map((val, key) =>{                    
                return( 
                  <React.Fragment key={val.id}>              
                  <Col sm={3}>                    
                    <div key={val.id}  className="hover01 column">                                       
                      <a  href={val.link} title={val.title} target="_Blank" rel="noreferrer" className="thumbnail iconCuadro color-link" >
                          <figure style={{textAlign: "center"}}><img src={val.imgurl} width="90px" height="77px" alt="..."/></figure>
                         <p style={{textAlign: "center", fontSize : "12px" }}>{val.title}</p> 
                      </a>
                                    
                    </div> 
                  </Col>   
                  </React.Fragment>              
                )
                })
              }
            </Row>
          </div>
        </div>
      </div>
    </div> 
    <div className="modal right fade" id="myModalConsultores" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header bg-light" style={{textAlign: "center"}}>  
              Consultores ARCSA
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
          <div className="modal-body">                   
            <Row>                  
              {                       
                itemsConsultores.map((val, key) =>{                    
                return( 
                  <React.Fragment key={val.id}>              
                  <Col sm={3}>                    
                    <div key={val.id}  className="hover01 column">                                       
                      <a  href={val.link} title={val.title} target="_Blank" rel="noreferrer" className="thumbnail iconCuadro color-link" >
                          <figure style={{textAlign: "center"}}><img src={val.imgurl} width="90px" height="77px" alt="..."/></figure>
                      	<p style={{textAlign: "center"}}>{val.title}</p>  
                      </a>
                                    
                    </div> 
                  </Col>   
                  </React.Fragment>              
                )
                })
              }
            </Row>
          </div>
        </div>
      </div>
    </div> 
    
    <section className="section">   
      <div className="col-lg-12">           
          {/*<div className="header">
            <h3 className="sidebar-title" style={{textAlign: "center", color:'white'}}>   ACCESO A TRÁMITES SISTEMATIZADOS CON NUESTRA
                  INSTITUCIÓN</h3>
          </div>  */    } 
          <div className="sidebar">
            <div className="sidebar-item categories">                ​
              <br/>
              <div className="container-principal">
                <Row>
                  {items.map(val => (                      
                      <React.Fragment key={val.id}>                 
                      <Col sm={3}>              
                        {val.funcion === '1' ? (
                          <div key={val.id}  className="hover01 column">                                           
                            <a href="!#" data-toggle="modal" data-target="#myModalSimuladores" type="button" title={val.title}  className="thumbnail iconCuadro color-link" >
                              <figure style={{textAlign: "center"}}><img src={val.imgurl} width="90px" height="77px" alt="..."/></figure>                             
                              <p style={{textAlign: "center"}}>{val.title}</p>  
                            </a>                                                  
                          </div>
                          ): val.funcion === '2' ? (
                            <div key={val.id}  className="hover01 column">                                           
                              <a href="!#" data-toggle="modal" data-target="#myModalConsultores" type="button" title={val.title}  className="thumbnail iconCuadro color-link" >
                                <figure style={{textAlign: "center"}}><img src={val.imgurl} width="90px" height="77px" alt="..."/></figure>                             
                                <p style={{textAlign: "center"}}>{val.title}</p>  
                              </a>                                                  
                            </div>
                          ) :  val.funcion === '3' ? (
                            <div key={val.id}  className="hover01 column">                                           
                              <a href="!#" data-toggle="modal" data-target="#myModalPermisos" type="button" title={val.title}  className="thumbnail iconCuadro color-link" >
                                <figure style={{textAlign: "center"}}><img src={val.imgurl} width="90px" height="77px" alt="..."/></figure>                             
                                <p style={{textAlign: "center"}}>{val.title}</p>  
                              </a>                                                  
                            </div>
                          ) : 
                          (
                          <div key={val.id}  className="hover01 column">                                           
                            <a  href={val.link} title={val.title} target="_Blank" rel="noreferrer" className="thumbnail iconCuadro color-link" >
                              <figure style={{textAlign: "center"}}><img src={val.imgurl} width="90px" height="77px" alt="..."/></figure>                              
                              <p style={{textAlign: "center"}}>{val.title}</p> 
                            </a>                                           
                          </div>
                          )
                        }                        
                      </Col>  
                      </React.Fragment> 
                    ))}        
                </Row>    
              </div>   
            </div> 
          </div> 
      </div> 
    </section>  
          
    </>    
  )
}

export default GridAplicativos;