import FormEstadoCitas from '../form/form_estado_citas'
    function Correspondencia(){
        return(
            <main id="main"> 
                <section className="section">   
                    <div className="col-md-12">
                        <div className="sidebar">
                            <div className="sidebar-item categories img-back" >
                                <div className="container"><br/>                                                                         
                                    <FormEstadoCitas/>                             
                                </div>                    
                            </div>
                            <br/>
                        </div>
                    </div>
                </section>
            </main>           
        )
    }

export default Correspondencia;