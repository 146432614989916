import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import $ from 'jquery';
//  Import action
import { userMessage, sendMessage } from "../../actions/watson";
 
const Chat = ({ chat, userMessage, sendMessage }) => { 
  // Handle Users Message
  const [message, setMessage] = useState("");
  const endOfMessages = useRef(null);

  const [state] = React.useState({
    load_chat : 1
})
  const scrollToBottom = () => {
    state.load_chat = 1;
    endOfMessages.current.scrollIntoView({ behavior: "smooth" });
    state.load_chat = 0;
  };
  useEffect(scrollToBottom, [chat]);

  //  Function that handles user submission
  const handleClick = async (e) => {
    state.load_chat = 1;
    const code = e.keyCode || e.which;
    if (code === 13) {
      console.log(message); 
      userMessage(message);
      if($("#idop").val() === ""){
        sendMessage(message, 0, 0, 0, 0);
      }else{
        sendMessage(message, 0, 0, $("#idop").val());
      }    
      setMessage("");
    }
  };

  
  function play_stop_video(){
   if($("#playstop").val() === ""){
      $("#playstop").val("stop");
      if($(".video_stop").get(0) !== undefined){
        var media = $(".video_stop").get(0);
        media.pause();
        media.currentTime = 0;
      }    
   }else{
      if($("#playstop").val() === "stop"){
        $("#playstop").val("play");
        if($(".video_stop").get(0) !== undefined){
          var media = $(".video_stop").get(0);
          media.play();
        }    
      }else{
        $("#playstop").val("stop");
        if($(".video_stop").get(0) !== undefined){
          var media = $(".video_stop").get(0);
          media.pause();
          media.currentTime = 0;
        } 
      }
   }   
  }

  function stop_video(){
    if($(".video_stop").get(0) !== undefined){
        var media = $(".video_stop").get(0);
        media.pause();
        media.currentTime = 0;
      } 
  }

  function seleccionChat(id, descripcion){
    state.load_chat = 1;
    userMessage(descripcion);
    if($("#idop").val() === ""){
      sendMessage(descripcion, id, 1, 0);
    }else{
      sendMessage(descripcion, id, 1, $("#idop").val());
    }    
    $("#idop").val(id);
  } 
  return (
    <>    
    <input type="hidden" id="idop" />
    <input type="hidden" id="playstop"/>
    <div className="modal-content modal-content-chat main-chat-content">
       
      <div className="modal-header-chat">                
          <div className="">
              {/*<ForumIcon/> ArcsaBot*/}
              <img className="img-bot" src="../img/head.png" alt=""/>             
              {/*<video className="banner-top" autoplay loop >
                <source src="http://172.16.0.22/videos/saludos.mp4" type="video/mp4"/>
                <source src="movie.ogg" type="video/ogg"/>                
              </video>*/} 
          </div>
          <button onClick={(e) => stop_video()}  type="button" className="close_chat" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div className="modal-body-chat">          
          <div className="chat"> 
            <div className="historyContainer">    
              <div class="col-md-12 col-lg-12 col-md-offset-12">
                <div class="panel panel-default chat-widget">
                  
                  {
                    chat.length === 0 
                    ? "" 
                    : chat.map((msg, key) =>          
                      <React.Fragment key={key}>
                        {
                          msg.tipo === 'Archie' ? ( 
                            msg.op === 'Ini' ? (
                              <>  
                                            
                                  <div class="form-group">
                                    <div class="message">
                                        <div class="avatar">
                                        <img class="img-circle avatar" alt="chat avatar" src="../img/chatbot.png"/>
                                        </div>
                                        <div class="message-text-wrapper">
                                          <div class="message-text">
                                          {
                                              msg.message.length > 0 ? (
                                                msg.message.map((val, key) =>
                                                <React.Fragment key={key}>
                                                  {
                                                    val.descripcion === '1' ? (
                                                      <>
                                                      <a href="#/"  style={{cursor:'pointer', color:'darkblue'}}  onClick={(e) => seleccionChat(val.id, val.aplicativo)}>{val.aplicativo}</a> <br/>
                                                      </>
                                                    ):(
                                                      <>
                                                      
                                                      <label  style={{fontWeight:'bold'}}>{val.aplicativo}</label> <br/>
                                                      </>
                                                    )
                                                  
                                                  }
                                                </React.Fragment>
                                                ) 
                                              ):(
                                                <div>Disculpa, no entiendo tu solicitud, selecciona una de las opciones presentadas o comunícate a: 
                                                  <br/> Contacto Ciudadano. <a rel="noreferrer" target="_Blank" href="https://aplicaciones.administracionpublica.gob.ec/">(https://aplicaciones.administracionpublica.gob.ec/)</a> 
                                                  <br/>  Te esperamos! </div>
                                              )  
                                            }      
                                          </div>
                                        </div>
                                    </div>
                                  </div>      
                              </>
                            ):(
                              msg.op === 'respArcsa' ? (

                                <div class="message">
                                        <div class="avatar">
                                        <img class="img-circle avatar" alt="chat avatar" src="../img/chatbot.png"/>
                                        </div>
                                        <div class="message-text-wrapper">
                                          <div class="message-text">
                                          { 
                                    msg.message.length > 0 ? (                                
                                        msg.message.map((val, key) =>
                                        <React.Fragment key={key}>
                                            {
                                          val.descripcion === '1' ? (
                                            
                                            <div id={val.aplicativo}>
                                              {/*<label style={{fontWeight:'bold'}}>{val.pregunta}</label> <br/>*/}
                                              <a href="#/" style={{cursor:'pointer', color:'darkblue'}}  onClick={(e) => seleccionChat(val.id, val.aplicativo)}>{val.aplicativo}</a> <br/>
                                            </div>
                                            ):(
                                              val.descripcion === '2' ? (
                                                <>
                                                <div onClick={(e) => play_stop_video()} dangerouslySetInnerHTML={{ __html: val.aplicativo }} ></div>
                                                </>
                                              ):(
                                                <>
                                                <label  style={{fontWeight:'bold'}}>{val.aplicativo}</label> <br/>
                                                </>
                                              )
                                            )
                                            }</React.Fragment>
                                        )                                  
                                    ):(
                                      <div>Disculpa, no entiendo tu solicitud, selecciona una de las opciones presentadas o comunícate a: 
                                      <br/> Contacto Ciudadano. <a rel="noreferrer" target="_Blank" href="https://aplicaciones.administracionpublica.gob.ec/">(https://aplicaciones.administracionpublica.gob.ec/)</a> 
                                      <br/>  Te esperamos! </div>
                                    )
                                  }  
                                          </div>
                                        </div>
                                    </div>
                              ):(   
                                
                                <div class="message">
                                <div class="avatar">
                                <img class="img-circle avatar" alt="chat avatar" src="../img/chatbot.png"/>
                                </div>
                                <div class="message-text-wrapper">
                                  <div class="message-text">
                                  {
                                    msg.message.length > 0 ? (
                                      msg.message.map((val, key) =>
                                      <React.Fragment key={key}>
                                      {
                                      val.descripcion === '1' ? (
                                        <>
                                        <a href="#/"  style={{cursor:'pointer', color:'darkblue'}}  onClick={(e) => seleccionChat(val.id, val.aplicativo)}>{val.aplicativo}</a> <br/>
                                        </>
                                      ):(
                                        val.descripcion === '2' ? (
                                          <>
                                          <div onClick={(e) => play_stop_video()} dangerouslySetInnerHTML={{ __html: val.aplicativo }} ></div>
                                          </>
                                        ):(
                                          <>
                                          <label  style={{fontWeight:'bold'}}>{val.aplicativo}</label> <br/>
                                          </>
                                        )
                                      )
                                      }</React.Fragment>
                                    ) 
                                    ):(
                                      <>
                                      
                                      <div>Disculpa, no entiendo tu solicitud, selecciona una de las opciones presentadas o comunícate a: 
                                                  <br/> Contacto Ciudadano. <a rel="noreferrer" target="_Blank" href="https://aplicaciones.administracionpublica.gob.ec/">(https://aplicaciones.administracionpublica.gob.ec/)</a> 
                                                  <br/>  Te esperamos! </div>
                                      </>
                                    )
                                  }    
                                  </div>
                                </div>
                            </div>
                              )                        
                            )                     
                          ):(
                            <div class="message message-right">
                                    <div class="avatar">
                                    <img class="img-circle avatar" alt="chat avatar" src="../img/icon-user.png"/>
                                    </div>
                                    <div class="message-text-wrapper">
                                      <div class="message-text">
                                      <div dangerouslySetInnerHTML={{ __html: msg.message }} ></div>      

                                      </div>
                                    </div>
                                </div>
                           
                          )
                        }
                      </React.Fragment>
                    )
                  }
                   { (state.load_chat === 1) &&
                                    <button class="btn-success col-md-12" disabled>
                                        <span class="spinner-border spinner-border-sm"></span>
                                         Cargando..
                                      </button>      
                                  }    
                                       
                  <div ref={endOfMessages}></div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className="modal-footer-chat">
        <input
          className="form-control pull-left inpu-chat"
          id="chatBox"
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleClick}
          value={message}
          placeholder="Escribe palabra clave o solicitud y presiona enter"
        ></input>
      </div>
    </div> 
    </>   
  );
};
const mapStateToProps = (state) => ({
  chat: state.watson.messages,
});

export default connect(mapStateToProps, { userMessage, sendMessage })(Chat);
