import React from 'react'
import { Container, Row, Col } from 'reactstrap';
//import { FaFacebook, FaTwitter, FaLinkedin, FaYoutube, FaInstagram } from 'react-icons/fa';
import '../css/footer.css'
function Footer(){
    return(        
        <>          
        <footer id="footer" className="footer from-group">
            <div className="container">                
                <Container>
                    <Row>
                        {/*<Col sm={12}>
                            <br/>
                        <div className="rounded-social-buttons">
                            <a className="social-button facebook" rel="noreferrer" href="https://www.facebook.com/" target="_blank"><FaFacebook/></a>
                            <a className="social-button twitter" rel="noreferrer" href="https://www.twitter.com/" target="_blank"><FaTwitter/></a>
                            <a className="social-button linkedin" rel="noreferrer" href="https://www.linkedin.com/" target="_blank"><FaLinkedin/></a>
                            <a className="social-button youtube" rel="noreferrer" href="https://www.youtube.com/" target="_blank"><FaYoutube/></a>
                            <a className="social-button instagram" rel="noreferrer" href="https://www.instagram.com/" target="_blank"><FaInstagram/></a>
                        </div>
                        </Col>*/}
                        <Col sm={12}> 
                            <div className="credits">
                                &copy; Copyright {new Date().getFullYear()} <strong><span>ARCSA</span></strong>.<br/>
                                Dirección de Tecnología de la Información y Comunicación
                            </div>
                        </Col> 
                        <br/>                   
                    </Row>
                </Container>                           
            </div> 
        </footer>

        </>
    )
}

export default Footer;