import '../css/form_citas.css'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
/*import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';*/
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import React, { useState, useEffect } from "react";

export default function MyForm(){
    const urlConsultarConsideraciones = "/servicios/citas_consideraciones_arcsa";
    const urlConsultarTemasCitas = "/servicios/citas_temas_arcsa";
    const [openModalInfo, setOpenModalInfo] = React.useState(false);
    const [itemsCitas, setItemsCitas] = useState([]);
    const [itemsConsideraciones, setItemsConsideraciones] = useState([]);    

    useEffect(() => {
        let formData = new FormData();
        formData.append('arcsa', '');
            const requestOptions = {
            method: 'POST',
            body: formData
        };
        //función para consultar procesos        
        const fetchItemsTemasCitas = async () => {         
            setOpenModalInfo(true);   
            const data = await fetch (urlConsultarTemasCitas,requestOptions);
            const itemsCitas = await data.json();
            setItemsCitas(itemsCitas.data[0]); 
        }      
        //función para consultar procesos
        const fetchItemsConsideraciones = async () => {      
            const data = await fetch (urlConsultarConsideraciones,requestOptions);
            const itemsConsideraciones = await data.json();
            setItemsConsideraciones(itemsConsideraciones.data[0]); 
        } 
        fetchItemsTemasCitas();        
        fetchItemsConsideraciones();

    }, []);

    
       
    
    const handleClose = () => {
        setOpenModalInfo(false);
    };
    return (        
        <>
        <Dialog open={openModalInfo}  onClose={handleClose}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title"><b>Información Importante</b></DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ol className="olList">
                            {
                                itemsConsideraciones.length > 0 ? (
                                    itemsConsideraciones.map((val, key) => (                                          
                                        <li id={key} >{val.consideracion}</li>                                                      
                                    ))
                                ):(
                                  <label>No hay citas disponibles</label>
                                )                                
                            }  
                            </ol>
                        </div>
                    </div>
                </div>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
                Cerrar
            </Button>
            </DialogActions>
        </Dialog>
        <div className="row">
            <div className="col-lg-12 form-group">          
                <fieldset>                    
                    <div className="alert alert-success alert-dismissable">
                        <div className="row text-center">
                            <div className="col-md-12">
                                Citas - ARCSA                       
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>         
            <div className="container">
                <div className="row">
                    {
                        itemsCitas.length > 0 ? (
                            itemsCitas.map((val, key) => (                                
                                <div id={key} className="col-lg-6">
                                    <a href="!#">
                                    <div className="card card-margin">
                                        <div className="card-header no-border">
                                        </div>
                                        <div className="card-body pt-0">
                                            <a href={val.ruta}>
                                                <div className="widget-49">
                                                    <div className="widget-49-title-wrapper text-center">
                                                        <div className="widget-49-date-primary">
                                                            <DoubleArrowIcon/>                                                        </div>
                                                        <div className="widget-49-meeting-info">
                                                            <span className="widget-49-pro-title">{val.title}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div> 
                                    </a>  
                                </div>                                                             
                            ))
                        ):(
                            <label>No hay citas disponibles</label>                      
                        )                                
                    }  
                    
                </div>
            </div>
        </div>
        </>        
    );
      
}